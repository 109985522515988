export const transformProductList = (productList: any): any => {
  return productList?.content.map((product: any, index: number) => {
    return {
      key: index + 1,
      variety: `${String(product?.commercial_name)} (${String(
        product?.localized_crop_info?.name.name
      )})`,
      manufacturer: product.manufacturer || "No Data Found !",
      measure_unit: "N/A",
      id: product.id,
    };
  });
};
