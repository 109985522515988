import React from "react";
import { Button, Flex, InputNumber } from "antd";
import { type RowColumnProps } from "./type";

const MATRIX = {
  ROW: 99,
  COLUMN: 99,
};
const RowColumn: React.FC<RowColumnProps> = ({ inputValue, setInputValue }) => {
  const handleIncrement = (field: keyof typeof inputValue): void => {
    setInputValue((prevState) => ({
      ...prevState,
      [field]: prevState[field] + 1,
    }));
  };

  const handleDecrement = (field: keyof typeof inputValue): void => {
    setInputValue((prevState) => ({
      ...prevState,
      [field]: Math.max(0, prevState[field] - 1),
    }));
  };

  return (
    <>
      <Flex vertical={false} gap={12}>
        <div>
          <label htmlFor="Rows">Rows</label>
          <div className="input-main">
            <Button
              data-testid="minus-button-rows"
              onClick={() => {
                handleDecrement("rows");
              }}
              disabled={!inputValue.rows}
            >
              -
            </Button>
            <InputNumber
              type="number"
              data-testid="row-input"
              value={inputValue.rows}
              min={0}
              max={MATRIX.ROW}
              onChange={(value) => {
                value && setInputValue({ ...inputValue, rows: value });
              }}
              controls={false}
            />
            <Button
              data-testid="plus-button-rows"
              disabled={!(inputValue.rows < MATRIX.ROW)}
              onClick={() => {
                handleIncrement("rows");
              }}
            >
              +
            </Button>
          </div>
        </div>
        <div>
          <label htmlFor="Columns">Columns</label>
          <div className="input-main">
            <Button
              data-testid="minus-button-columns"
              onClick={() => {
                handleDecrement("columns");
              }}
              disabled={!inputValue.columns}
            >
              -
            </Button>
            <InputNumber
              type="number"
              data-testid="column-input"
              value={inputValue.columns}
              min={0}
              max={MATRIX.COLUMN}
              onChange={(value) => {
                value && setInputValue({ ...inputValue, columns: value });
              }}
              controls={false}
            />
            <Button
              data-testid="plus-button-columns"
              disabled={!(inputValue.columns < MATRIX.COLUMN)}
              onClick={() => {
                handleIncrement("columns");
              }}
            >
              +
            </Button>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default RowColumn;
