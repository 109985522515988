import type { OrganizationDataType } from "../../pages/hall-of-organisations/types";

const trialingPlanId = process.env.REACT_APP_TRIALING_APP_ID;

export const orgLicencing = (orgs: any, licensingData: any): any => {
    const orgLicencingMap: any = {};
    licensingData?.forEach((data: any) => {
        data?.contracts?.forEach((contract: any) => {
            if (contract?.app_id === trialingPlanId) {
                orgLicencingMap[contract.workspace_id] = true;
            }
            else if (orgLicencingMap[contract.workspace_id] !== true) {
                orgLicencingMap[contract?.workspace_id] = false;
            }
        })
    })
    return orgLicencingMap;
}

export const applyOrgLiecningToOrgs = (orgs: any, licencingStatus: any): any => {
    if (Array.isArray(orgs?.content)) {
        return {
            content: orgs?.content?.map((org: any) => {
                org.hasAccess = licencingStatus[org.workspace_id];
                return org;
            })
        }
    }
    return { content: [] };

}