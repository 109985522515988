export const DEFAULT_MAP_COORDINATES = { lat: -74.0060152, lon: 40.7127281 };
export const MAP_STYLE = "mapbox://styles/mapbox/satellite-v9";

/* link colorRange object to the colorMatrixObject pending
 */

export const colorRange = {
  1: 20000,
  2: 30000,
  3: 40000,
  4: 50000,
  5: 60000,
  6: 70000,
  7: 80000,
  8: 90000,
};

export const colorMatrixObject = {
  blueColorShade: {
    [colorRange[1]]: "rgba(198, 230, 255, 1)",
    [colorRange[2]]: "rgba(130, 207, 255,1)",
    [colorRange[3]]: "rgba(49, 180, 242,1)",
    [colorRange[4]]: "rgba(0, 146, 228,1)",
    [colorRange[5]]: "rgba(0, 113, 205, 1)",
    [colorRange[6]]: "rgba(0, 86, 147, 1)",
    [colorRange[7]]: "rgba(0, 59, 105, 1)",
    [colorRange[8]]: "rgba(0, 39, 71, 1)",
  },

  greenColorShade: {
    [colorRange[1]]: "rgba(195, 234, 209,1)",
    [colorRange[2]]: "rgba(144, 214, 170, 1)",
    [colorRange[3]]: "rgba(94, 187, 127, 1)",
    [colorRange[4]]: "rgba(25, 160, 75, 1)",
    [colorRange[5]]: "rgba(20, 128, 60, 1)",
    [colorRange[6]]: "rgba(12, 97, 44, 1)",
    [colorRange[7]]: "rgba(6, 67, 28, 1)",
    [colorRange[8]]: "rgba(3, 45, 16, 1)",
  },

  purpleColorShade: {
    [colorRange[1]]: "rgba(231, 221, 252, 1)",
    [colorRange[2]]: "rgba(210, 189, 249, 1)",
    [colorRange[3]]: "rgba(186, 153, 246, 1)",
    [colorRange[4]]: "rgba(150, 100, 240, 1)",
    [colorRange[5]]: "rgba(131, 84, 214, 1)",
    [colorRange[6]]: "rgba(100, 58, 170, 1)",
    [colorRange[7]]: "rgba(70, 39, 121, 1)",
    [colorRange[8]]: "rgba(46, 25, 84, 1)",
  },

  tealColorShade: {
    [colorRange[1]]: "rgba(169, 239, 232,1)",
    [colorRange[2]]: "rgba(95, 210, 200, 1)",
    [colorRange[3]]: "rgba(70, 187, 176, 1)",
    [colorRange[4]]: "rgba(43, 156, 146, 1)",
    [colorRange[5]]: "rgba(33, 124, 116, 1)",
    [colorRange[6]]: "rgba(22, 92, 86, 1)",
    [colorRange[7]]: "rgba(13, 65, 60, 1)",
    [colorRange[8]]: "rgba(6, 43, 39, 1)",
  },

  orangeColorShade: {
    [colorRange[1]]: "rgba(255, 218, 206, 1)",
    [colorRange[2]]: "rgba(255, 181, 157, 1)",
    [colorRange[3]]: "rgba(255, 120, 90, 1)",
    [colorRange[4]]: "rgba(238, 91, 58, 1)",
    [colorRange[5]]: "rgba(193, 70, 43, 1)",
    [colorRange[6]]: "rgba(146, 51, 30, 1)",
    [colorRange[7]]: "rgba(104, 34, 19, 1)",
    [colorRange[8]]: "rgba(72, 21, 10, 1)",
  },

  pinkColorShade: {
    [colorRange[1]]: "rgba(255, 216, 223,1)",
    [colorRange[2]]: "rgba(255, 178, 193, 1)",
    [colorRange[3]]: "rgba(255, 125, 150, 1)",
    [colorRange[4]]: "rgba(232, 93, 120, 1)",
    [colorRange[5]]: "rgba(187, 72, 93, 1)",
    [colorRange[6]]: "rgba(141, 53, 69, 1)",
    [colorRange[7]]: "rgba(101, 35, 47, 1)",
    [colorRange[8]]: "rgba(69, 22, 30, 1)",
  },

  yellowColorShade: {
    [colorRange[1]]: "rgba(255, 228, 17, 1)",
    [colorRange[2]]: "rgba(240, 195, 85, 1)",
    [colorRange[3]]: "rgba(233, 153, 33, 1)",
    [colorRange[4]]: "rgba(193, 126, 25, 1)",
    [colorRange[5]]: "rgba(154, 100, 18, 1)",
    [colorRange[6]]: "rgba(116, 74, 11, 1)",
    [colorRange[7]]: "rgba(82, 51, 5, 1)",
    [colorRange[8]]: "rgba(55, 33, 2, 1)",
  },

  cyanColorShade: {
    [colorRange[1]]: "rgba(204, 247, 255,1)",
    [colorRange[2]]: "rgba(173, 242, 255, 1)",
    [colorRange[3]]: "rgba(117, 234, 255, 1)",
    [colorRange[4]]: "rgba(60, 225, 255, 1)",
    [colorRange[5]]: "rgba(4, 217, 255, 1)",
    [colorRange[6]]: "rgba(0, 173, 204, 1)",
    [colorRange[7]]: "rgba(0, 130, 15, 1)",
    [colorRange[8]]: "rgba(0, 87, 102, 1)",
  },
};

export const blueColorShade = {
  [colorRange[1]]: "rgba(198, 230, 255, 1)",
  [colorRange[2]]: "rgba(130, 207, 255,1)",
  [colorRange[3]]: "rgba(49, 180, 242,1)",
  [colorRange[4]]: "rgba(0, 146, 228,1)",
  [colorRange[5]]: "rgba(0, 113, 205, 1)",
  [colorRange[6]]: "rgba(0, 86, 147, 1)",
  [colorRange[7]]: "rgba(0, 59, 105, 1)",
  [colorRange[8]]: "rgba(0, 39, 71, 1)",
};

export const greenColorShade = {
  [colorRange[1]]: "rgba(195, 234, 209,1)",
  [colorRange[2]]: "rgba(144, 214, 170, 1)",
  [colorRange[3]]: "rgba(94, 187, 127, 1)",
  [colorRange[4]]: "rgba(25, 160, 75, 1)",
  [colorRange[5]]: "rgba(20, 128, 60, 1)",
  [colorRange[6]]: "rgba(12, 97, 44, 1)",
  [colorRange[7]]: "rgba(6, 67, 28, 1)",
  [colorRange[8]]: "rgba(3, 45, 16, 1)",
};

export const purpleColorShade = {
  [colorRange[1]]: "rgba(231, 221, 252, 1)",
  [colorRange[2]]: "rgba(210, 189, 249, 1)",
  [colorRange[3]]: "rgba(186, 153, 246, 1)",
  [colorRange[4]]: "rgba(150, 100, 240, 1)",
  [colorRange[5]]: "rgba(131, 84, 214, 1)",
  [colorRange[6]]: "rgba(100, 58, 170, 1)",
  [colorRange[7]]: "rgba(70, 39, 121, 1)",
  [colorRange[8]]: "rgba(46, 25, 84, 1)",
};

export const tealColorShade = {
  [colorRange[1]]: "rgba(169, 239, 232,1)",
  [colorRange[2]]: "rgba(95, 210, 200, 1)",
  [colorRange[3]]: "rgba(70, 187, 176, 1)",
  [colorRange[4]]: "rgba(43, 156, 146, 1)",
  [colorRange[5]]: "rgba(33, 124, 116, 1)",
  [colorRange[6]]: "rgba(22, 92, 86, 1)",
  [colorRange[7]]: "rgba(13, 65, 60, 1)",
  [colorRange[8]]: "rgba(6, 43, 39, 1)",
};

export const orangeColorShade = {
  [colorRange[1]]: "rgba(255, 218, 206, 1)",
  [colorRange[2]]: "rgba(255, 181, 157, 1)",
  [colorRange[3]]: "rgba(255, 120, 90, 1)",
  [colorRange[4]]: "rgba(238, 91, 58, 1)",
  [colorRange[5]]: "rgba(193, 70, 43, 1)",
  [colorRange[6]]: "rgba(146, 51, 30, 1)",
  [colorRange[7]]: "rgba(104, 34, 19, 1)",
  [colorRange[8]]: "rgba(72, 21, 10, 1)",
};

export const pinkColorShade = {
  [colorRange[1]]: "rgba(255, 216, 223,1)",
  [colorRange[2]]: "rgba(255, 178, 193, 1)",
  [colorRange[3]]: "rgba(255, 125, 150, 1)",
  [colorRange[4]]: "rgba(232, 93, 120, 1)",
  [colorRange[5]]: "rgba(187, 72, 93, 1)",
  [colorRange[6]]: "rgba(141, 53, 69, 1)",
  [colorRange[7]]: "rgba(101, 35, 47, 1)",
  [colorRange[8]]: "rgba(69, 22, 30, 1)",
};

export const yellowColorShade = {
  [colorRange[1]]: "rgba(255, 228, 17, 1)",
  [colorRange[2]]: "rgba(240, 195, 85, 1)",
  [colorRange[3]]: "rgba(233, 153, 33, 1)",
  [colorRange[4]]: "rgba(193, 126, 25, 1)",
  [colorRange[5]]: "rgba(154, 100, 18, 1)",
  [colorRange[6]]: "rgba(116, 74, 11, 1)",
  [colorRange[7]]: "rgba(82, 51, 5, 1)",
  [colorRange[8]]: "rgba(55, 33, 2, 1)",
};

export const cyanColorShade = {
  [colorRange[1]]: "rgba(204, 247, 255,1)",
  [colorRange[2]]: "rgba(173, 242, 255, 1)",
  [colorRange[3]]: "rgba(117, 234, 255, 1)",
  [colorRange[4]]: "rgba(60, 225, 255, 1)",
  [colorRange[5]]: "rgba(4, 217, 255, 1)",
  [colorRange[6]]: "rgba(0, 173, 204, 1)",
  [colorRange[7]]: "rgba(0, 130, 15, 1)",
  [colorRange[8]]: "rgba(0, 87, 102, 1)",
};

export const colorMatrix = [
  blueColorShade,
  greenColorShade,
  purpleColorShade,
  tealColorShade,
  orangeColorShade,
  pinkColorShade,
  yellowColorShade,
  cyanColorShade,
];
