import {
  type StepItemType,
  // type FieldDetailsType,
  type PlotCardDetaillsInterface,
} from "./types";
// import fieldImg1 from "../../assets/images/field-img-1.svg";
import plotCardIcon1 from "../../assets/images/PlotCardIcon1.svg";
import plotCardIcon2 from "../../assets/images/PlotCardIcon2.svg";
// import plotCardIcon3 from "../../assets/images/PlotCardIcon3.svg";
import plotCardIcon4 from "../../assets/images/PlotCardIcon4.svg";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";

export const PlotDesigns = (): any => {
  const { t } = useTranslation();
  const PlotCardDetails: PlotCardDetaillsInterface[] | any = [
    {
      id: 1,
      icon: plotCardIcon1,
      name: "A/B Line + Machinery Grid",
      key: "ab_line",
      description: formatTranslation(t("trials.plotDesign.abLine")),
    },

    {
      id: 3,
      icon: plotCardIcon2,
      name: "Buffer Zone",
      key: "buffer_zone",
      description: formatTranslation(t("trials.plotDesign.bufferZone")),
    },
    {
      id: 4,
      icon: plotCardIcon4,
      name: "Trial Plots",
      key: "trial_plots",
      description: formatTranslation(t("trials.plotDesign.trialPlots")),
    },
  ];
  return PlotCardDetails;
};

export const Step: StepItemType[] = [
  {
    title: "Basic info",
  },
  {
    title: "Plot Design",
  },
  {
    title: "Rates & Dosage",
  },
  {
    title: "Summary",
  },
];

export const menuItems = [
  {
    label: "Save",
    key: "save",
  },
  {
    label: "Save and Export Work Orders",
    key: "openModel",
  },
];
