export const calculateFieldsInfo = (
  fields: Array<{ declared_area: string }>
): { total: number; numberOfFields: number } => {
  let numberOfFields = 0;
  let total = 0;

  if (fields?.length > 0) {
    total = fields.reduce(
      (acc: number, farm: { declared_area: string }) =>
        acc + parseInt(farm?.declared_area),
      0
    );
    numberOfFields = fields?.length;
  }

  return { total, numberOfFields };
};
