import { useEffect, useState } from "react";
import AxiosInstance from "../utils/axios";
import PlatFormAPIAxiosInstance from "../utils/platFormAxios";

const useAxios = (
  url: string,
  platFormAPIs?: boolean
): { loading: boolean; error: string | null | Error; response: any } => {
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [loading, setloading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await (platFormAPIs ? PlatFormAPIAxiosInstance : AxiosInstance)
        .get(url)
        .then((response: any) => {
          setResponse(response.data);
        })
        .catch((error: Error) => {
          setError(error.message);
        })
        .finally(() => {
          setloading(false);
        });
    };
    void fetchData();
  }, [url]);

  return { loading, error, response };
};

export default useAxios;
