import { type FC } from "react";
import { type DeleteIconProps } from "../types";
import "./deleteIcon.less";

export const DeleteIcon: FC<DeleteIconProps> = ({
  onClick,
  children,
  disabled,
}) => {
  return (
    <>
      {disabled === true ? (
        <img className="delete-button-disabled">{children}</img>
      ) : (
        <img onClick={onClick} className="delete-button">
          {children}
        </img>
      )}
    </>
  );
};
