import { Tabs } from "antd";
import { type FC } from "react";
import { RATES_DOSAGE_ITEMS } from "./constant";
import { type SeedsComponentProps } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import { RateUnitDropDown } from "./seeds";

const RatesDosageComponent: FC<SeedsComponentProps> = (trialData) => {
  const { t } = useTranslation();
  const onChange = (key: string): void => {};

  return (
    <div className="rates-dosage-main">
      <div className="title-sec">
        <h3 className="main-title">
          {formatTranslation(t("newTrial.ratesDosage.title"))}
        </h3>
      </div>
      <Tabs
        tabBarExtraContent={<RateUnitDropDown />}
        defaultActiveKey="0"
        items={RATES_DOSAGE_ITEMS(trialData)}
        onChange={onChange}
      />
    </div>
  );
};

export default RatesDosageComponent;
