import React from "react";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { type TrialDataInterface } from "./types";
import { Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";
import { route } from "../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";

export const TrialListColumns = (
  handleDeleteTrialData: any,
  openModal: any
): any => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigation = (id: string): void => {
    navigate(`/app/${route.TRIALS.path}/${id}`);
  };

  const TRIAL_COLUMNS = [
    {
      title: formatTranslation(t("trials.tableTitle1")),
      dataIndex: "name",
      key: "name",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        a.name.localeCompare(b.name),
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: formatTranslation(t("trials.tableTitle2")),
      dataIndex: "protocol_name",
      key: "protocol_name",
      sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
        a.protocol_name.localeCompare(b.protocol_name),
    },
    // {
    //   title: formatTranslation(t("trials.tableTitle3")),
    //   dataIndex: "assignee_id",
    //   sorter: (a: TrialDataInterface, b: TrialDataInterface) =>
    //     a.assignee_id.localeCompare(b.assignee_id),
    //   key: "assignee_id",
    // },
    {
      title: formatTranslation(t("trials.tableTitle4")),
      key: "action",
      render: (_: any, record: any) => (
        <div className="action-btn">
          <Button
            className={record.is_exportable ? "" : "export-workorder-icon"}
            disabled={record.is_exportable === false}
            onClick={(): void => {
              openModal(record?.id, record.field_ids[0]);
            }}
          >
            <DownloadOutlined />
          </Button>
          <Button
            onClick={() => {
              handleNavigation(record.id);
            }}
          >
            <EditOutlined />
          </Button>
          <Popconfirm
            rootClassName="trial-delete-popup"
            title={`Delete the ${record.name as string}?`}
            description={
              <span className="del-label">
                {formatTranslation(t("trials.deleteText"))}
              </span>
            }
            icon={<QuestionCircleOutlined />}
            onConfirm={() => handleDeleteTrialData(record.id)}
            onCancel={() => {}}
          >
            <Button className="del-btn">
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </div>
      ),
      fixed: true,
      width: 100,
    },
  ];
  return TRIAL_COLUMNS;
};
