import { type FC, type ReactNode } from "react";
import { AuthProvider } from "../lib/auth/AuthContext";

interface ProviderProp {
  children: ReactNode;
}
const AppProviders: FC<ProviderProp> = ({ children }): JSX.Element => {
  return (
    <AuthProvider>
      {/* <ContextPovider>{children}</ContextPovider> */}
      {/* <GlobalProvider></GlobalProvider> */}
      {children}
    </AuthProvider>
  );
};
export default AppProviders;
