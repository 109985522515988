import { type ReactElement } from "react";
import { FarmCard } from "../../shared/layout/FarmCard";
import useAxios from "../../shared/custom-hooks/useAxios";

const PropertyCard = ({
  property,
}: {
  property: { id: string; name: string; org_id: string };
}): ReactElement => {
  const { response: fieldsResp } = useAxios(
    `/properties/${property?.id}/fields`,
    true
  );

  const { response: cyclesResp } = useAxios(
    `/properties/${property?.id}/cycles`,
    true
  );

  return (
    <FarmCard
      key={property?.id}
      farmName={property?.name}
      farmDetail={fieldsResp}
      cyclesDetail={cyclesResp}
      orgId={property.org_id}
      id={property.id}
    />
  );
};

export default PropertyCard;
