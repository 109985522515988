import { type FC } from "react";
import { PlotCard } from "../../../shared/layout/PlotCard";
import { PlotDesigns } from "../constants";
import { type PlotCardDetaillsInterface } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

interface PlotDesignComponentProps {
  trialData?: any;
}

const PlotDesignComponent: FC<PlotDesignComponentProps> = (trialData) => {
  const { t } = useTranslation();
  const PlotCardDetails = PlotDesigns();
  return (
    <div className="rates-main">
      <h3 className="title">
        {formatTranslation(t("newTrial.plotDesign.title"))}
      </h3>
      <p className="description">
        {formatTranslation(t("newTrial.plotDesign.description"))}
      </p>
      <div>
        {PlotCardDetails?.map(
          (item: PlotCardDetaillsInterface, key: number) => (
            <PlotCard
              data={item}
              key={`${item.name}-${key}`}
              trialData={trialData}
            />
          )
        )}
      </div>
    </div>
  );
};

export default PlotDesignComponent;
