import { useEffect, useState, type ReactElement } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Button, Dropdown, Form } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CurrentSteps from "../../shared/layout/Steps";
import {
  getCancelButtonLabel,
  getCurrentStepNumber,
  getItems,
  getNextButtonLabel,
  nextStep,
  prevStep,
} from "./utils";
import arrowBack from "../../assets/images/arrow-back.svg";
import downIcon from "../../assets/images/keyboard-down-18.svg";
import leftIcon from "../../assets/images/left-arr.svg";
import rightGrayIcon from "../../assets/images/right-arr-gray.svg";
import rightWhiteIcon from "../../assets/images/right-arr-white.svg";
import "../style.less";
import "./style.less";
import {
  Outlet,
  useNavigate,
  useLocation,
  useOutletContext,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { type ProtocolStepItemType } from "./types";

import { protocolsSteps, SUBMIT_PROTOCOL_LABEL } from "./constants";
import { route } from "../constant";
import AxiosInstance from "../../shared/utils/axios";
import { useAppState } from "../../lib/appContext/AppContext";
// import { type protocolType } from "../protocols/types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import type {
  createProtocolFormType,
  createProtocolValidationDataType,
  ProtocolResponse,
} from "./shared/interfaces/product-interfaces";

export type nextStepOptions = "draft" | "published" | "configure-trial";

const CreateProtocol = (): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm<createProtocolFormType>();
  const formValues = form.getFieldsValue(true);
  const [formValidationData, setFormValidationData] =
    useState<createProtocolValidationDataType>({});

  const [checkValue, setCheckValue] = useState<boolean>(false);

  const { stateValue } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const currentStepNumber = getCurrentStepNumber(currentPath);

  const items = getItems();
  const [searchParams, setSearchParams] = useSearchParams();

  const protocolId = searchParams.get("protocol_id");

  const values = Form.useWatch([], form);

  useEffect(() => {
    if (currentStepNumber === 1) {
      if (formValidationData.protocolType) setCheckValue(true);
    }
  }, [values, formValues]);

  useEffect(() => {
    if (currentStepNumber === 2) {
      form
        .validateFields({ validateOnly: true, dirty: false })
        .then(() => {
          setCheckValue(true);
        })
        .catch(() => {
          setCheckValue(false);
        });
    }
  }, [values, formValues]);

  useEffect(() => {
    if (currentStepNumber === 3) {
      if (
        formValidationData.objectives &&
        formValidationData.objectives.length > 0
      ) {
        setCheckValue(true);
      } else {
        setCheckValue(false);
      }
    }

    if (currentStepNumber === 4) {
      if (
        formValidationData.products &&
        formValidationData.products.length > 0 &&
        formValidationData?.measure_unit
      ) {
        setCheckValue(true);
      } else {
        setCheckValue(false);
      }
    }
  }, [form, formValues, formValidationData]);

  const handleNextStep = async (
    nextStepOption: nextStepOptions
  ): Promise<void> => {
    if (protocolsSteps[currentStepNumber]) {
      amplitude.logEvent("Protocol-Steps", {
        buttonName: protocolsSteps[currentStepNumber]?.title,
      });
    }

    const formValues = form.getFieldsValue(true);
    const nextStepData: ProtocolStepItemType = nextStep(currentPath);

    if (currentStepNumber === 1) {
      navigate(nextStepData.path);
      if (protocolId !== null) {
        navigate({
          pathname: nextStepData.path,
          search: createSearchParams({
            protocol_id: protocolId,
          }).toString(),
        });
      }
    }

    if (currentStepNumber === 2) {
      const payload = {
        org_id: stateValue.orgValue?.id,
        name: formValues.protocolName,
        description: formValues.protocolDescription,
        crop: formValues.protocolCrop,
        crop_details: {
          country: formValues.protocolCountry,
          crop_id: formValues.protocolCropId,
        },
        protocol_type: formValues.protocolType,
        plot_minimum_size:
          isNaN(formValues.plotMinWidth) ||
            isNaN(formValues.plotMinLength) ||
            formValues.plotMinLength === null ||
            formValues.plotMinWidth === null
            ? {}
            : {
              width: formValues.plotMinWidth,
              length: formValues.plotMinLength,
            },
        plot_recommended_size:
          isNaN(formValues.plotRecommendedWidth) ||
            isNaN(formValues.plotRecommendedLength) ||
            formValues.plotRecommendedWidth === null ||
            formValues.plotRecommendedLength === null
            ? {}
            : {
              width: formValues.plotRecommendedWidth,
              length: formValues.plotRecommendedLength,
            },
      };

      if (protocolId === null) {
        const res: ProtocolResponse = await AxiosInstance.post(
          "/protocol",
          payload
        );

        if (res?.data?.id) {
          setSearchParams({ protocol_id: res?.data?.id });
          // else error handling

          navigate({
            pathname: nextStepData.path,
            search: createSearchParams({
              protocol_id: res?.data?.id,
            }).toString(),
          });
        } else {
          toast.error(formatTranslation(t("createProtocol.error")));
          console.error(formatTranslation(t("createProtocol.error")));
        }
      } else if (protocolId) {
        try {
          await AxiosInstance.patch(`/protocol/${protocolId}`, payload);
          navigate({
            pathname: nextStepData.path,
            search: createSearchParams({
              protocol_id: protocolId,
            }).toString(),
          });
        } catch (error) {
          console.error(formatTranslation(t("common.error.patch")), error);
        }
      }
    }

    if (currentStepNumber === 3 && protocolId !== null) {
      const payload = {
        objectives: formValues.objectives,
      };

      await AxiosInstance.patch(`/protocol/${protocolId}`, payload);
      navigate({
        pathname: nextStepData.path,
        search: createSearchParams({
          protocol_id: protocolId,
        }).toString(),
      });
    }

    if (currentStepNumber === 4 && protocolId !== null) {
      if (!formValues?.product_details?.density_range?.min) {
        formValues.product_details.density_range = null;
      }
      const payload = {
        product_details: formValues.product_details,
      };
      try {
        const response = await AxiosInstance.patch(
          `/protocol/${protocolId}`,
          payload
        );
        if (response?.status === 200) {
          navigate({
            pathname: nextStepData.path,
            search: createSearchParams({
              protocol_id: protocolId,
            }).toString(),
          });
        } else {
          toast.error(formatTranslation(t("protocol.toastMessage.patch")));
          console.error("Error in saving protocol products");
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (currentPath === items[items?.length - 1].path && protocolId !== null) {
      let payload = {};
      nextStepOption === "draft"
        ? (payload = { status: "draft" })
        : (payload = { status: "published" });

      await AxiosInstance.patch(`/protocol/${protocolId}`, payload);
      navigate({
        pathname: `/app/${nextStepOption === "configure-trial"
          ? route.NEWTRIAL.path
          : route.PROTOCOLS_DASHBOARD.path
          }`,
        // optional search param
        // search: createSearchParams({
        //   protocol_id: protocolId,
        // }).toString(),
      });
    }
  };

  const handlePrevStep = (): void => {
    const pervStepData: ProtocolStepItemType | any = prevStep(currentPath);
    if (pervStepData !== undefined)
      if (protocolId !== null) {
        // check first step
        navigate({
          pathname: pervStepData?.path,
          search: createSearchParams({
            protocol_id: protocolId,
          }).toString(),
        });
      } else {
        navigate(pervStepData.path);
      }
    else navigate(`/app/${route.PROTOCOLS_DASHBOARD.path}`);
  };

  const [showSubmitOptionsFlag, setShowSubmitOptionsFlag] =
    useState<boolean>(false);

  const showSubmitOptions = (): void => {
    setShowSubmitOptionsFlag(true);
  };

  const hideSubmitOptions = (): void => {
    setShowSubmitOptionsFlag(false);
  };

  return (
    <div className="createProtocol-page">
      <div className="createProtocol-header">
        <div className="breadcrumbs" data-testid="breadcrumbs-div">
          <Button className="Left-button-icon" onClick={handlePrevStep}>
            <img src={arrowBack} alt="left-icon" />
          </Button>
          <p>{formatTranslation(t("createProtocol.title"))}</p>
        </div>
        <div className="stepper-common">
          <CurrentSteps current={currentStepNumber - 1} items={items} />
        </div>
      </div>
      <Form
        className="createProtocol-body"
        data-testid="form-basic"
        name="basic"
        form={form}
        layout="vertical"
        initialValues={{
          protocolType: null,
          plotRecommendedWidth: null,
          plotRecommendedLength: null,
          plotMinWidth: null,
          plotMinLength: null,
        }}
      >
        {" "}
        <ToastContainer />
        <div className="router-outlet">
          <Outlet
            context={{ form, formValidationData, setFormValidationData }}
          />
        </div>
        <div className="bottom-sec-btn">
          <Button
            size="large"
            className="cancel-btn"
            data-testid="cancel-btn"
            onClick={handlePrevStep}
          >
            {<img src={leftIcon} style={{ color: "black" }} alt="left-icon" />}
            {getCancelButtonLabel(currentStepNumber)}
          </Button>

          <div className="split-button">
            <Button
              size="large"
              onClick={() => {
                void (async () => {
                  await handleNextStep("configure-trial");
                })();
              }}
              className={`next-btn ${currentStepNumber === 5 ? "protocol-summary-btn" : ""
                } ${checkValue ? "" : "active"}`}
              htmlType="submit"
              data-testid="next-btn"
              disabled={!checkValue}
            >
              {getNextButtonLabel(currentStepNumber)}
              {getNextButtonLabel(currentStepNumber) !==
                SUBMIT_PROTOCOL_LABEL && (
                  <img
                    src={checkValue ? rightWhiteIcon : rightGrayIcon}
                    alt="left-icon"
                  />
                )}
            </Button>

            {currentStepNumber === 5 && protocolId !== null ? (
              <>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: formatTranslation(
                          t("createProtocol.dropdownOption1")
                        ),
                        key: "draft",
                      },
                      {
                        label: formatTranslation(
                          t("createProtocol.dropdownOption2")
                        ),
                        key: "published",
                      },
                    ],
                    onClick: (e) => {
                      amplitude.logEvent("Protocol-Steps", {
                        buttonName: e.key,
                      });
                      void (async () => {
                        await handleNextStep(e.key as nextStepOptions);
                      })();
                      hideSubmitOptions();
                    },
                  }}
                >
                  <Button
                    className="next-btn protocol-save-options "
                    size="large"
                  >
                    {
                      <img
                        src={downIcon}
                        className="protocol-save-options-icon"
                        alt="down-icon"
                      />
                    }
                  </Button>
                </Dropdown>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export function useCreateProtocolForm(): any {
  return useOutletContext<createProtocolFormType>(); // optional type
}

export default CreateProtocol;
