import React, { useState } from "react";
import {
  type combineType,
  type PlotCardDetaillsInterface,
  type TrailPlotsDataInterface,
  type NewTrialStateInterface,
  type AbLine,
  type BufferZone,
  type Plots,
  type TrialPlot,
  type Replicants,
} from "../../../pages/new-trail/types";
import { notification } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";

const EXTERNAL = "EXTERNAL";
const INTERNAL = "INTERNAL";

export const usePlotCardState = (): any => {
  const [api, contextHolder] = notification.useNotification();
  const { newTrialState, setNewTrialState } = useNewTrialState();
  const [popoverState, setPopoverState] = useState<
    Record<string, number | null>
  >({
    openPopover: 0,
    openEditPopover: 0,
  });
  const [deleteData, setDeleteData] = useState<combineType>();

  const abHexCodes = [
    "#0092E4",
    "#EE5B3A",
    "#9664F0",
    "#E85D78",
    "#2B9C92",
    "#C17E19",
  ];

  const abClassNames = [
    "ab-blue",
    "ab-orange",
    "ab-purple",
    "ab-pink",
    "ab-teal",
    "ab-yellow",
  ];

  const getAbLineColor = (
    id: number,
    type: "className" | "hexCode"
  ): string => {
    for (let i = 0; i < newTrialState.ab_line.length; i++) {
      if (newTrialState.ab_line[i].id === id) {
        return type === "className" ? abClassNames[i] : abHexCodes[i];
      }
    }
    return "null";
  };

  const handleTickedData = (data: any, indexToUpdate: number): void => {
    if (data.key === "ab_line") {
      const updatedAbLine = newTrialState.ab_line.map(
        (item: any, index: number) => {
          if (item.id === indexToUpdate) {
            return { ...item, selected: !item.selected };
          }
          return item;
        }
      );

      setNewTrialState((prevState: any) => ({
        ...prevState,
        ab_line: updatedAbLine,
      }));
    }
  };

  const toggleVisibility = (
    index: number,
    data: { key: keyof NewTrialStateInterface; id?: number },
    keyName?: string | undefined
  ): void => {
    let currentVal: any;
    if (data.key === "trial_plots" && keyName === undefined) {
      currentVal = newTrialState[data.key]?.map((val: TrialPlot, i: number) => {
        if (i === index) {
          return {
            ...val,
            plot: { ...val.plot, visible: !val?.plot.visible },
          };
        }
        return val;
      });
    } else if (data.key === "trial_plots" && keyName === "replicant") {
      currentVal = newTrialState[data.key]?.map((val: TrialPlot) => {
        return {
          ...val,
          replicants: val.replicants?.map((replicant: Replicants) => {
            if (replicant.id === data.id) {
              return {
                ...replicant,
                visible: !replicant.visible,
              };
            }
            return replicant;
          }),
        };
      });
    } else {
      currentVal = newTrialState[data.key]?.map((val: any, i: number) => {
        if (i === index) {
          return { ...val, visible: !val?.visible };
        }
        return val;
      });
    }
    setNewTrialState((prevState: NewTrialStateInterface) => ({
      ...prevState,
      [data.key as string]: currentVal,
    }));
  };

  const handleAddData = (addData: PlotCardDetaillsInterface): void => {
    const cardData: AbLine = {
      id: new Date().getTime(),
      selected: true,
      name: `${addData?.name?.split("+")[0].trim()} ${
        +newTrialState?.ab_line?.length + 1
      }`,
      visible: true,
      gridWidth: 100,
      grid_width: 100,
      key: "A/B Line + Machinery Grid",
      geojson: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [0, 0],
        },
      },
    };
    setNewTrialState((prevState: NewTrialStateInterface) => ({
      ...prevState,
      ab_line: [...(newTrialState?.ab_line ?? []), cardData],
    }));
  };

  const handleBufferZoneData = (
    data: number,
    openPopoverKey?: string | undefined,
    val?: number | undefined
  ): void => {
    if (openPopoverKey === "openEditPopover") {
      setNewTrialState((prevState: NewTrialStateInterface) => {
        const updatedBufferZone = prevState.buffer_zone.map(
          (zone: BufferZone) => {
            if (zone.id === val) {
              return {
                ...zone,
                type: data > 0 ? EXTERNAL : INTERNAL,
                size: data,
              };
            }
            return zone;
          }
        );

        return {
          ...prevState,
          buffer_zone: updatedBufferZone,
        };
      });
    } else {
      const zoneData: BufferZone = {
        id: new Date().getTime(),
        type: data > 0 ? EXTERNAL : INTERNAL,
        name: "Buffer Zone",
        key: "Buffer Zone",
        size: data,
        visible: true,
      };
      setNewTrialState((prevState: NewTrialStateInterface) => ({
        ...prevState,
        buffer_zone: [zoneData],
      }));
    }
  };

  const handleMachineGridData = (width: number, index?: number): void => {
    setNewTrialState((prevState: NewTrialStateInterface) => {
      const abLines = prevState.ab_line;
      abLines[index ?? 0].grid_width = width ?? 0;
      return {
        ...prevState,
        ab_line: abLines,
      };
    });
  };

  const handleAbLineData = (name: string, index?: number): void => {
    setNewTrialState((prevState: NewTrialStateInterface) => {
      const abLines = prevState.ab_line;
      abLines[index ?? 0].name = name ?? 0;
      return {
        ...prevState,
        ab_line: abLines,
      };
    });
  };

  const handleTrailPlotsData = (
    rows: number,
    columns: number,
    plotWidth: number,
    plotHeight: number,
    openPopoverKey?: string | undefined,
    val?: number | undefined,
    indexKey?: number | undefined
  ): void => {
    if (openPopoverKey === "openEditPopover") {
      setNewTrialState((prevState: NewTrialStateInterface) => {
        const updatedTrialPlots = prevState.trial_plots.map(
          (item: TrialPlot, i: number) => {
            if (i === indexKey) {
              const updatedReplicants = item.replicants?.map(
                (replicant: Replicants) => ({
                  ...replicant,
                  rows,
                  columns,
                  plotWidth,
                  plotHeight,
                  column_size: plotWidth,
                  row_size: plotHeight,
                })
              );
              return {
                ...item,
                plot: {
                  ...item.plot,
                  rows,
                  columns,
                  plotWidth,
                  plotHeight,
                  column_size: plotWidth,
                  row_size: plotHeight,
                },
                replicants: updatedReplicants,
              };
            }
            return item;
          }
        );
        return {
          ...prevState,
          trial_plots: updatedTrialPlots,
        };
      });
    } else {
      const newTitle = `Plots ${+newTrialState?.trial_plots?.length + 1}`;
      const trialData: Plots = {
        id: new Date().getTime(),
        key: "Trial Plots",
        name: newTitle,
        visible: true,
        rows,
        columns,
        column_size: plotWidth,
        row_size: plotHeight,
        plotWidth,
        plotHeight,
        geojson: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [0, 0],
          },
        },
      };
      setNewTrialState((prevState: any) => {
        return {
          ...prevState,
          trial_plots: [
            ...(prevState?.trial_plots ?? null),
            { plot: trialData },
          ],
        };
      });
    }
  };

  const handleReplicantData = (
    dataValue: TrailPlotsDataInterface,
    i: number
  ): void => {
    const replicantsTitle = `${
      newTrialState?.trial_plots[i]?.replicants?.length === undefined ||
      isNaN(newTrialState?.trial_plots[i]?.replicants?.length)
        ? "Replicant 1"
        : `Replicant ${+newTrialState?.trial_plots[i]?.replicants?.length + 1}`
    }`;
    const newTitle = replicantsTitle;
    const { plot } = dataValue;
    const addReplicantData: Replicants = {
      ...plot,
      key: "trial_plots",
      id: new Date().getTime(),
      parentId: dataValue.plot.id,
      visible: true,
      name: newTitle,
      geojson: {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [0, 0],
        },
      },
    };

    setNewTrialState((prevState: NewTrialStateInterface) => ({
      ...prevState,
      trial_plots: (prevState?.trial_plots ?? []).map(
        (plot: TrialPlot, index: number) =>
          index === i
            ? {
                ...plot,
                replicants: [...(plot?.replicants ?? []), addReplicantData],
              }
            : plot
      ),
    }));
  };

  const handleDeleteData = (
    deletedData: combineType,
    key: keyof NewTrialStateInterface,
    replicantKey?: string | undefined
  ): any => {
    const isReplicant =
      key === "trial_plots" && replicantKey === "replicantKey";
    const replicantValue =
      isReplicant &&
      newTrialState[key]?.map((val: TrialPlot) => {
        const updatedVal = {
          ...val,
          replicants: val.replicants?.filter(
            (replicantData: Replicants) => replicantData.id !== deletedData.id
          ),
        };
        return updatedVal;
      });

    const currentVal = Array.isArray(newTrialState[key])
      ? (newTrialState[key] as TrialPlot[]).filter((val: any) => {
          if (key === "trial_plots" && replicantKey === "") {
            return val.plot?.id !== deleteData?.id;
          } else {
            return val.id !== deleteData?.id;
          }
        })
      : [];

    setNewTrialState((prevState: NewTrialStateInterface) => ({
      ...prevState,
      [key]: isReplicant ? replicantValue : currentVal,
    }));

    const notificationMessage = React.createElement(
      "div",
      { className: "title-sec" },
      React.createElement(CheckCircleOutlined),
      `${deletedData.name} Deleted`
    );
    api.open({
      message: notificationMessage,
      className: "delete-plot-notification",
      duration: 2,
    });
  };

  return {
    handleReplicantData,
    handleAddData,
    handleBufferZoneData,
    handleMachineGridData,
    handleAbLineData,
    handleTrailPlotsData,
    toggleVisibility,
    deleteData,
    setDeleteData,
    popoverState,
    setPopoverState,
    api,
    contextHolder,
    handleDeleteData,
    handleTickedData,
    getAbLineColor,
  };
};
