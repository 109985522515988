import { Flex, Form, Input, InputNumber, Select, Space } from "antd";
import { useEffect, useState, type ReactElement } from "react";
import { formatCropsData } from "../utils";
import { useCreateProtocolForm } from "../index";
import { Await, useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../shared/utils/axios";
import PlatFormAPIAxiosInstance from "../../../shared/utils/platFormAxios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import type { protocolCountries } from "../shared/interfaces/product-interfaces";
const MIN_ALLOWABLE_PLOT_SIZE = 1;

const BasicInfoComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form } = useCreateProtocolForm();
  const [searchParams] = useSearchParams();

  const protocolId = searchParams.get("protocol_id");
  const { Option } = Select;

  const [existingData, setExistingData] = useState<any>(null);
  const [countries, setCountries] = useState<protocolCountries[]>([]);
  const [countryCodes, setCountryCodes] = useState<string>("");
  const [cropContry, setCropCountry] = useState<any[]>([]);
  const [cropLoading, setCropLoading] = useState<boolean>(false);
  let cropOptions: any[] = [];

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData) {
      form.setFieldsValue({
        protocolName: existingData?.name,
        protocolDescription: existingData?.description,
        protocolCountry: existingData?.crop_details?.country,
        protocolCropId: existingData?.crop_details?.crop_id,
        protocolCrop: existingData?.crop,
        plotMinLength: existingData?.plot_minimum_size?.length,
        plotMinWidth: existingData?.plot_minimum_size?.width,
        plotRecommendedLength: existingData?.plot_recommended_size?.length,
        plotRecommendedWidth: existingData?.plot_recommended_size?.width,
      });

      setCountryCodes(existingData?.crop_details?.countries);
    }
  }, [existingData]);

  const handleCountries = (value: string): void => {
    if (value !== "") {
      form.setFieldsValue({
        protocolCountry: value, 
        protocolCrop: undefined,
        protocolId: undefined,
      });
      setCountryCodes(value);
    } else {
      setCountryCodes("");
      setCropCountry([]);
    }
  };

  const ApiCall = async (country: string): Promise<any> => {
    try {
      setCropLoading(true);
      const response = await PlatFormAPIAxiosInstance.get(
        `crops?showDeleted=false&location=${country ?? ""}`
      );
      setCropLoading(false);
      return response?.data?.content;
    } catch (error) {
      console.error("Error While Fetching Data", error);
    }
  };

  const fetchData = async (countryCodes: string): Promise<void> => {
    const CropData = [countryCodes].map(async (item: any) => await ApiCall(item));
    try {
      const res = await Promise.all(CropData);
      setCropCountry(res.flat());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(countryCodes).catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, [countryCodes]);

  cropOptions = formatCropsData(cropContry);

  const sortedCropOptions = [...cropOptions].sort((a: any, b: any) =>
    a?.label?.toLowerCase().localeCompare(b?.label?.toLowerCase())
  );

  const getCountries = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get("/countries");
      setCountries(response.data?.content);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCountries().catch((error) => {
      console.error("Error fetching data:", error);
    });
  }, []);

  return (
    <div className="basicInfo-component">
      <div className="basicInfo-container">
        <h2>{formatTranslation(t("createProtocol.basicInfo.title"))}</h2>
        <p>{formatTranslation(t("createProtocol.basicInfo.description"))}</p>
        <div className="basicInfo-form-section">
          <Flex className="protocol-form-section-name">
            <Form.Item
              key="name"
              name="protocolName"
              label={formatTranslation(
                t("createProtocol.basicInfo.protocolName")
              )}
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => {
                  if (form)
                    form.setFieldsValue({ protocolName: e.target.value });
                }}
              ></Input>
            </Form.Item>
            <Form.Item
              key="protocolDescription"
              name="protocolDescription"
              label={formatTranslation(
                t("createProtocol.basicInfo.protocolDescription")
              )}
              rules={[{ required: true }]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({ protocolDescription: e.target.value });
                }}
              ></Input>
            </Form.Item>
          </Flex>
        </div>
        <Flex gap={"16px"}>
          <Form.Item
            className="basicInfo-form-section"
            key="protocolCountry"
            name="protocolCountry"
            label="Country"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="select Country"
              onChange={handleCountries}
            >
              {countries.map((country: protocolCountries) => (
                <Option key={country.code} value={country.code}  label={country.name}>
                 {country.name}
                </Option>                
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            className="basicInfo-form-section"
            key="protocolCrop"
            name="protocolCrop"
            label={formatTranslation(t("createProtocol.basicInfo.protocolCrop"))}
            rules={[{ required: true }]}
          >
            <Select
              loading={cropLoading}
              showSearch
              allowClear
              optionFilterProp="label"
              placeholder="select one crop"
              defaultValue={"select"}
              onChange={(value) => {
                const cropName = sortedCropOptions.find(
                  (item) => item?.value === value
                );
                form.setFieldsValue({
                  protocolCropId: value,
                  protocolCrop: cropName?.label,
                });
              }}
              options={sortedCropOptions}
              optionRender={(option) => <Space>{option?.data?.label}</Space>}
            />
          </Form.Item>
        </Flex>
        <Flex gap={"16px"}>
          <div className="basicInfo-form-section">
            <div>
              {formatTranslation(t("createProtocol.basicInfo.plotMinimumSize"))}
            </div>
            <Flex gap={"12px"}>
              <Form.Item
                key="plotMinWidth"
                name="plotMinWidth"
                label={formatTranslation(t("createProtocol.basicInfo.width"))}
                rules={[
                  { required: true },
                  {
                    type: "number",
                    max: form.getFieldValue("plotRecommendedWidth"),
                    message: formatTranslation(
                      t("createProtocol.basicInfo.plotMinimumSizeMessage")
                    ),
                  },
                ]}
              >
                <InputNumber
                  controls={true}
                  size="large"
                  min={1}
                  max={100000}
                  className="basicInfo-input-num"
                  onChange={(value) => {
                    form.setFieldsValue({ plotMinWidth: value });
                  }}
                />
              </Form.Item>
              <Form.Item
                key="plotMinLength"
                name="plotMinLength"
                label={formatTranslation(t("createProtocol.basicInfo.length"))}
                rules={[
                  { required: true },
                  {
                    type: "number",
                    max: form.getFieldValue("plotRecommendedLength", true),
                    message: formatTranslation(
                      t("createProtocol.basicInfo.plotMinimumSizeMessage")
                    ),
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  min={1}
                  max={100000}
                  className="basicInfo-input-num"
                  onChange={(value) => {
                    form.setFieldsValue({ plotMinLength: value });
                  }}
                />
              </Form.Item>
            </Flex>
          </div>

          <div className="basicInfo-form-section">
            <div>
              {formatTranslation(
                t("createProtocol.basicInfo.plotRecommendedSize")
              )}
            </div>
            <Flex gap={"12px"}>
              <Form.Item
                key="plotRecommendedWidth"
                name="plotRecommendedWidth"
                label={formatTranslation(t("createProtocol.basicInfo.width"))}
                rules={[
                  { required: true },
                  {
                    type: "number",
                    min: Math.max(form.getFieldValue("plotMinWidth", true), MIN_ALLOWABLE_PLOT_SIZE),
                    message: formatTranslation(
                      t("createProtocol.basicInfo.plotRecommendedSizeMessage")
                    ),
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  className="basicInfo-input-num"
                  onChange={(value) => {
                    form.setFieldsValue({ plotRecommendedWidth: value });
                  }}
                />
              </Form.Item>
              <Form.Item
                key="plotRecommendedLength"
                name="plotRecommendedLength"
                label={formatTranslation(t("createProtocol.basicInfo.length"))}
                rules={[
                  { required: true },
                  {
                    type: "number",
                    min: Math.max(form.getFieldValue("plotMinLength", true), MIN_ALLOWABLE_PLOT_SIZE),
                    message: formatTranslation(
                      t("createProtocol.basicInfo.plotRecommendedSizeMessage")
                    ),
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  className="basicInfo-input-num"
                  onChange={(value) => {
                    form.setFieldsValue({ plotRecommendedLength: value });
                  }}
                />
              </Form.Item>
            </Flex>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default BasicInfoComponent;
