import { useState, type FC } from "react";
import { Popover } from "antd";
import downArrowIcon from "../../../../assets/images/arrow-down.svg";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../../../lib/appContext/AppContext";
import "../style.less";
import { getInitials } from "../utils";
import { route } from "../../../../pages/constant";

export const OrganizationSelect: FC = () => {
  const [open, setOpen] = useState<boolean>();
  const navigate = useNavigate();
  const { stateValue, setStateValue } = useAppState();

  const handleOpenChange = (newOpen: boolean): void => {
    setOpen(newOpen);
  };

  const handleSelectValue = (val: any): void => {
    setStateValue({
      ...stateValue,
      orgValue: val,
    });
    setOpen(false);
    navigate(`/app/${route.FARMS.path}`, { state: { id: val.id } });
  };
  const content = (
    <div>
      <div className="upper-content">
        <div className="select-values">
          {stateValue.orgsData?.content.map((val: any, i: number) => (
            <Button
              disabled={!val.hasAccess}
              key={`${val.name as string}-${i}`}
              onClick={() => {
                handleSelectValue(val);
              }}
            >
              <div className="splits">{getInitials(val.name)}</div>
              {val.name}
            </Button>
          ))}
        </div>
      </div>
      <div className="goto-org">
        <Button
          onClick={() => {
            navigate(`/app/${route.ORGS.path}`);
            setOpen(false);
          }}
        >
          Go to Organizations
        </Button>
      </div>
    </div>
  );
  return (
    <div className="org-select-dropdown-main">
      <Popover
        content={content}
        title="Organization"
        trigger="click"
        arrow={false}
        rootClassName="org-select-dropdown"
        placement="bottomLeft"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <button className="select-dropdown">
          <h2>Organization</h2>
          <div className="start-select">
            <h4>
              {stateValue.orgValue === null
                ? "All organization"
                : stateValue?.orgValue?.name}
            </h4>
            <img src={downArrowIcon} alt="downArrowIcon" />
          </div>
        </button>
      </Popover>
    </div>
  );
};
