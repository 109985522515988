import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "../../../config/styles/theme.less";
import "./style.less";
import mapboxgl from "mapbox-gl";
import { DEFAULT_MAP_COORDINATES, MAP_STYLE } from "./constants";
import {
  type IMapComponentProps,
  type IMapComponentRef,
} from "../../interfaces/map.interface";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN ?? "";

const MapComponent = forwardRef<IMapComponentRef, IMapComponentProps>(
  (props, ref) => {
    const {
      lat = DEFAULT_MAP_COORDINATES.lat,
      lon = DEFAULT_MAP_COORDINATES.lon,
      onLoad,
    } = props;

    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);

    useEffect(() => {
      if (mapContainerRef.current && !map) {
        const mapInstance = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: MAP_STYLE,
          center: [lat, lon],
          zoom: 5,
          maxZoom: 50,
          attributionControl: false,
        });

        mapInstance.addControl(
          new mapboxgl.NavigationControl({
            showCompass: false,
            visualizePitch: true,
            showZoom: true,
          }),
          "bottom-right"
        );

        mapInstance.on("load", () => {
          if (onLoad) {
            onLoad(mapInstance);
          }
        });
        setMap(mapInstance);
      }
    }, [map, onLoad]);

    useImperativeHandle(
      ref,
      () => ({
        getMap: () => map,
        mapContainerRef: mapContainerRef.current,
      }),
      [map, mapContainerRef]
    );

    return (
      <div
        ref={mapContainerRef}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          width: "100%",
          zIndex: 0,
        }}
      >
        {props.children}
      </div>
    );
  }
);

MapComponent.displayName = "MapComponent";
export default React.memo(MapComponent);
