import { useEffect, useState } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { Step } from "../constants";
import { DatePicker, Form, type FormInstance } from "antd";
import { type PickerComponentClass } from "antd/es/date-picker/generatePicker/interface";
import AxiosInstance from "../../../shared/utils/axios";
import { useNewTrialState } from "./NewTrialStateProvider";
import {
  type Replicants,
  type TrialPlot,
  type NewTrialStateInterface,
  type allCombineType,
  type BufferZone,
  type MachineryGrid,
  type AbLine,
} from "../types";
import { useAppState } from "../../../lib/appContext/AppContext";
import useAxios from "../../../shared/custom-hooks/useAxios";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { addRatesDosagesToPlotProperties } from "./addRatesDosagesToPlotProperties";
import { useAuth } from "../../../lib/auth/AuthContext";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

export interface UseFormHandlingInterface {
  error: string;
  form: FormInstance<any>;
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
  checkValue: boolean;
  setCheckValue: React.Dispatch<React.SetStateAction<boolean>>;
  fieldData: any;
  setFieldData: React.Dispatch<React.SetStateAction<any>>;
  RangePicker: PickerComponentClass<any>;
  handleFieldSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
  next: () => void;
  prev: () => void;
  onFinish: (data: any) => any;
  setdefaultAssigneeId: () => string;
}

const useFormHandling = (): UseFormHandlingInterface => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [current, setCurrent] = useState<number>(0);
  const [checkValue, setCheckValue] = useState<boolean>(true);
  const [fieldData, setFieldData] = useState<any>([]);
  const [error, setError] = useState("");
  const {
    newTrialState,
    setTrialInfo,
    trialInfo,
    ratesDosage,
    ratesDosageValidation,
  } = useNewTrialState();
  const { id } = useParams();
  const { stateValue } = useAppState();
  const { user } = useAuth();
  const { response: farmProperties } = useAxios(
    `/properties/${
      stateValue?.farmValue?.id as string
    }/fields?attributes=geometry`,
    true
  );

  const removeKeysFromObject = (
    obj: allCombineType,
    keysToRemove: string[],
    excludeKeys: string[] = []
  ): any =>
    Object.fromEntries(
      Object.entries(obj).filter(
        ([k]) => !keysToRemove.includes(k) && !excludeKeys.includes(k)
      )
    );

  const modifyTrialPlots = (item: TrialPlot): any => ({
    ...item,
    plot: removeKeysFromObject(item.plot, ["id", "visible", "key"]),
    replicants: item.replicants?.map((replicant: Replicants) =>
      removeKeysFromObject(replicant, ["id", "visible", "key", "parentId"])
    ),
  });

  const removeCollapseValueKey = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(removeCollapseValueKey);
    } else if (typeof obj === "object" && obj !== null) {
      const newObj: any = {};
      for (const key in obj) {
        if (key !== "collapseValue") {
          if (key === "plot_id") {
            newObj[key] = parseInt(obj[key], 10);
          } else {
            newObj[key] = removeCollapseValueKey(obj[key]);
          }
        }
      }
      return newObj;
    }
    return obj;
  };

  const removeKeys = (): NewTrialStateInterface => {
    const modifiedData: NewTrialStateInterface = { ...newTrialState };
    (Object.keys(modifiedData) as Array<keyof NewTrialStateInterface>).forEach(
      (dataKey: keyof NewTrialStateInterface) => {
        modifiedData[dataKey] =
          modifiedData[dataKey]?.map(
            (i: AbLine | BufferZone | MachineryGrid | TrialPlot) =>
              removeKeysFromObject(i, ["id", "visible", "key"])
          ) ?? modifiedData[dataKey];
        if (dataKey === "ab_line")
          modifiedData[dataKey] = modifiedData[dataKey].map((i: any) =>
            removeKeysFromObject(i, [], ["ticked"])
          );
        if (dataKey === "buffer_zone" || dataKey === "machinery_grid")
          modifiedData[dataKey] = modifiedData[dataKey].map(
            (i: BufferZone | MachineryGrid) =>
              removeKeysFromObject(i, [], ["name"])
          );
        if (dataKey === "trial_plots")
          modifiedData[dataKey] = modifiedData[dataKey]?.map(modifyTrialPlots);
      }
    );
    modifiedData.machinery_grid.forEach((mgItem, index) => {
      if (mgItem) {
        mgItem.rotation = 0;
      }
    });
    return modifiedData;
  };

  const { RangePicker } = DatePicker;

  const getNext = (current: number): void => {
    amplitude.logEvent("Trial-Steps", { buttonName: Step[current + 1]?.title });
    setCurrent(current + 1);
  };

  const getNextButtonLabel = (current: number): string => {
    if (current === Step.length - 1)
      return formatTranslation(t("trials.finishButton"));
    return Step[current + 1].title;
  };

  const handleFieldSelection = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    form.setFieldsValue({ field_ids: e.target.value });
    setTrialInfo({
      ...trialInfo,
      field_ids: [e.target.value],
    });
  };

  const next = (): void => {
    getNext(current);
    setCheckValue(false);
  };

  const prev = (): void => {
    setCurrent((prevCurrent) => prevCurrent - 1);
    setCheckValue(false);
  };

  const onFinish = async (data: any): Promise<void> => {
    if (current === 0) {
      const payload = {
        ...data,
        start_date: dayjs(data?.rangePicker[0].$d).format("YYYY-MM-DD"),
        end_date: dayjs(data?.rangePicker[1].$d).format("YYYY-MM-DD"),
        org_id: stateValue?.orgValue?.id,
        property_id: stateValue?.farmValue?.id,
        field_ids: trialInfo.field_ids,
      };
      delete payload.rangePicker;
      try {
        if (id ?? trialInfo?.id) {
          const trialId: string = id ?? trialInfo?.id;
          const response = await AxiosInstance.patch(
            `/trial/${trialId}`,
            payload
          );
          setTrialInfo({
            ...payload,
            id: response?.data?.id,
          });
        } else {
          const response = await AxiosInstance.post("/trial", payload);
          setTrialInfo({
            ...payload,
            id: response?.data?.id,
          });
        }
      } catch (error) {
        console.error(formatTranslation(t("common.error.post")), error);
      }
    } else if (current === 1) {
      const updatedData = removeKeys();
      const updateApiData = {
        ...updatedData,
        buffer_zone: updatedData.buffer_zone[0],
        machinery_grid: updatedData.machinery_grid[0],
      };
      const response = await AxiosInstance.patch(
        `/trial/${trialInfo.id as string}`,
        updateApiData
      );
      if (!response) {
        setError(formatTranslation(t("common.error.update")));
      }
    } else if (current === 2) {
      // const updatedData = removeKeys(); // Need to check impact of not removing keys
      const updatedData: NewTrialStateInterface = { ...newTrialState };
      const cleanedRatesDosage = removeCollapseValueKey(ratesDosage);

      const updateApiData = {
        ...updatedData,
        buffer_zone: updatedData.buffer_zone[0],
        machinery_grid: updatedData.machinery_grid[0],
        rates_and_dosages: cleanedRatesDosage,
      };

      const transformedUpdateAPIData =
        addRatesDosagesToPlotProperties(updateApiData);
      delete transformedUpdateAPIData.rates_and_dosages;
      try {
        const response = await AxiosInstance.patch(
          `/trial/${trialInfo.id as string}`,
          transformedUpdateAPIData
        );
        if (!response) {
          setError(formatTranslation(t("common.error.update")));
        }
      } catch (error) {
        console.error(formatTranslation(t("common.error.patch")), error);
      }
    } else if (current === 3) {
      navigate("/app/trials");
    }

    if (
      getNextButtonLabel(current) !==
      formatTranslation(t("trials.finishButton"))
    ) {
      next();
    }
  };

  const values = Form.useWatch([], form);

  const setdefaultAssigneeId = (): string => {
    return user?.id;
  };

  useEffect(() => {
    if (user?.id) {
      form.setFieldsValue({ assignee_id: user?.id });
    }
  }, [user]);

  useEffect(() => {
    if (current === 0) {
      form
        .validateFields({ validateOnly: true })
        .then(() => {
          setCheckValue(true);
        })
        .catch(() => {
          setCheckValue(false);
        });
    }
  }, [values]);

  useEffect(() => {
    if (current === 1) {
      const hasAbLine = newTrialState.ab_line.length > 0;
      const hasPlotData =
        newTrialState.trial_plots.length > 0 &&
        newTrialState.trial_plots.every(
          (plot) => plot?.plot && Object.keys(plot).length > 0
        );
      if (hasAbLine && hasPlotData) {
        setCheckValue(true);
      } else {
        setCheckValue(false);
      }
    }
  }, [current, newTrialState]);

  useEffect(() => {
    if (current === 2) {
      if (!ratesDosageValidation) {
        setCheckValue(false);
      } else {
        setCheckValue(true);
      }
    }
  }, [current, ratesDosageValidation]);

  useEffect(() => {
    if (current === 3) {
      setCheckValue(true);
    }
  }, [current]);

  useEffect(() => {
    setFieldData(farmProperties?.content);
  }, [farmProperties]);

  return {
    error,
    form,
    current,
    setCurrent,
    checkValue,
    setCheckValue,
    RangePicker,
    setdefaultAssigneeId,
    handleFieldSelection,
    fieldData,
    setFieldData,
    next,
    prev,
    onFinish,
  };
};

export default useFormHandling;
