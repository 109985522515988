import { useEffect, useState, type ReactElement } from "react";
import { PTable } from "../../../shared/layout/PTable";
import { Button, Drawer, Flex, Select, Switch, Spin } from "antd";
import { Search } from "../../../shared/layout/Search";
import boxIcon from "../../../../src/assets/images/box-outline.svg";
import infoIcon from "../../../../src/assets/images/info_24px.svg";
import { useCreateProtocolForm } from "..";
import { measureUnitOptions } from "../constants";
import { DeleteIcon } from "../../../shared/layout/DeleteIcon";
import { useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../shared/utils/axios";
import PlatFormAPIAxiosInstance from "../../../shared/utils/platFormAxios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";
import { DensityRangeComponent } from "../shared/components/DensityRangeComponent";
import { ProductCard } from "../shared/components/ProductCard";
import { transformProductList } from "../shared/utils/common-utils";
import type { ProtocolResponse } from "../shared/interfaces/product-interfaces";

interface Product {
  key: number;
  id: string;
  variety: string;
  manufacturer: string;
  measure_unit: string;
  density_range?: {
    max?: number;
    min?: number;
  };
}
const ProductsComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form, formValidationData, setFormValidationData } =
    useCreateProtocolForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
  const [productList, setProductList] = useState<any>([]);
  const [globalDensityRange, setGlobalDensityRange] = useState<boolean>(false);
  const [globalDensityValues, setGlobalDensityValues] = useState<any>({
    max: 1,
    min: 1,
  });
  const [measureUnitSelection, setMeasureUnitSelection] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [searchParams] = useSearchParams();
  const protocolId = searchParams.get("protocol_id");
  const [existingData, setExistingData] = useState<any>(null);
  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData?.data) {
      setSelectedProducts(existingData.data.product_details?.products);
      setGlobalDensityRange(
        existingData.data.product_details?.global_density_range
      );

      setGlobalDensityValues({
        min: existingData.data.product_details?.density_range?.min,
        max: existingData.data.product_details?.density_range?.max,
      });
      setMeasureUnitSelection(existingData.data.product_details?.measure_unit);
      setFormValidationData({
        ...formValidationData,
        measure_unit: existingData.data.product_details?.measure_unit,
        products: existingData.data.product_details?.products,
      });

      getProductList(
        existingData?.data?.crop_details?.localized_crop_ids
      ).catch((e) => {
        console.error(e);
      });
      // get Product ids
      // find products in full list
      // mark them as checked/unchecked
      // add checked index to setSelectedRowKeys
      // add that array to selectedProducts
      // if does not exist in the full list, ignore
      // if the list changes, update again to check if selected items are included
    }
  }, [existingData, protocolId]);

  useEffect(() => {
    form.setFieldsValue({
      product_details: {
        density_range: {
          max: globalDensityValues.max,
          min: globalDensityValues.min,
        },
        global_density_range: globalDensityRange,
        measure_unit: measureUnitSelection,
        products: selectedProducts,
      },
    });
  }, [
    selectedProducts,
    globalDensityRange,
    globalDensityValues,
    measureUnitSelection,
  ]);

  const updateProductDensityRange = (
    productId: string,
    densityType: "max" | "min",
    value: number
  ): void => {
    if (globalDensityRange) {
      setGlobalDensityValues({ ...globalDensityValues, [densityType]: value });
    } else {
      const modifiedProducts = selectedProducts.map((product) => {
        if (product.id === productId) {
          return {
            ...product,
            density_range: { ...product.density_range, [densityType]: value },
          };
        } else return product;
      });
      setSelectedProducts(modifiedProducts);
    }
  };

  let formattedProducts: Product[] = [];

  const getProductList = async (localizedCropIds: string[]): Promise<void> => {
    const payload = {
      localized_crop_id: localizedCropIds,
      show_cononical: true,
    };

    const response: ProtocolResponse = await PlatFormAPIAxiosInstance.post(
      "/catalog/seeds/query?page=1&size=100",
      payload
    );
    setProductList(response?.data);
    setloading(false);
  };

  if (productList?.content)
    formattedProducts = transformProductList(productList);

  const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selected = formattedProducts.filter((product: Product) =>
      newSelectedRowKeys.find((x) => x === product.key)
    );
    setSelectedProducts(selected);
    setFormValidationData({ ...formValidationData, products: selected });
  };

  const onDeleteProduct = (key: number): void => {
    const indexTodDelete = selectedRowKeys.findIndex((x) => x === key);
    const updatedRowKeys = selectedRowKeys.filter(
      (x, index) => index !== indexTodDelete
    );
    setSelectedRowKeys(updatedRowKeys);
    const selected = formattedProducts.filter((product: Product) =>
      updatedRowKeys.find((x) => x === product.key)
    );
    setSelectedProducts(selected);
    setFormValidationData({ ...formValidationData, products: selected });
  };

  const onDeleteAllProducts = (): void => {
    setSelectedRowKeys([]);
    setSelectedProducts([]);
    setFormValidationData({ ...formValidationData, products: [] });
  };

  const columns = [
    {
      title: formatTranslation(t("createProtocol.products.tableTitle1")),
      dataIndex: "variety",
      key: "variety",
    },
    {
      title: formatTranslation(t("createProtocol.products.tableTitle2")),
      dataIndex: "manufacturer",
      key: "manufacturer",
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedProducts?.length > 0;

  const handleTableChange = (): void => {};

  const closeDrawer = (): void => {
    setIsDrawerOpen(false);
  };

  const handleMeasureUnitChange = (value: string): void => {
    setFormValidationData({
      ...formValidationData,
      measure_unit: value,
    });
    setMeasureUnitSelection(value);
    setSelectedProducts(
      selectedProducts?.map((item) => ({
        ...item,
        measure_unit: value,
      }))
    );
  };

  useEffect(() => {
    setSelectedProducts(
      selectedProducts?.map((item) => ({
        ...item,
        measure_unit: measureUnitSelection,
      }))
    );
  }, [selectedProducts?.length]);

  const toggleDensityRange = (e: boolean): void => {
    setGlobalDensityRange(e);
    if (!e) setGlobalDensityValues({ max: 1, min: 1 });
  };

  const [searchText, setSearchText] = useState<string>("");
  const [filteredProductsData, setFilteredProductsData] = useState<Product[]>();

  const searchData = (): void => {
    const filteredData = formattedProducts?.filter((entry: any) => {
      return entry.variety
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
    setFilteredProductsData(filteredData);
  };

  useEffect(() => {
    searchData();
  }, [searchText, formattedProducts?.length]);

  return (
    <div className="products-component">
      <div className="products-left">
        <p className="text-xl text-semibold">
          {formatTranslation(t("createProtocol.products.title"))}
        </p>
        <Search
          className="text-semibold products-search"
          searchText={searchText}
          setSearchTexts={setSearchText}
        />
        <div>
          <span style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys?.length} items` : ""}
          </span>
          {loading ? (
            <Flex gap="middle" align="center" vertical>
              <Spin size="large" tip="Loading" />
            </Flex>
          ) : (
            <PTable
              rowSelection={rowSelection}
              onChange={handleTableChange}
              columns={columns}
              dataSource={filteredProductsData ?? formattedProducts}
            />
          )}
        </div>
      </div>

      <div className="products-right">
        <div className="prodcuts-right-header">
          <h3 className="text-lg text-semibold">
            {formatTranslation(t("createProtocol.products.rightTilte"))}
          </h3>

          <Button
            className={
              !hasSelected
                ? "remove-products-btn--disabled"
                : "remove-products-btn"
            }
            disabled={!hasSelected}
            onClick={onDeleteAllProducts}
          >
            {" "}
            <DeleteIcon disabled={!hasSelected} />{" "}
            {formatTranslation(t("createProtocol.products.removeProducts"))}
          </Button>
        </div>

        <div className="drawer-options">
          <span>
            <Switch
              className="density-switch"
              size="small"
              defaultChecked={false}
              value={globalDensityRange ?? globalDensityRange}
              onChange={toggleDensityRange}
            />{" "}
            {formatTranslation(t("createProtocol.products.densityRange"))}{" "}
            <img src={infoIcon} />
          </span>
          <span>
            {formatTranslation(t("createProtocol.products.measureUnits"))}
            <Select
              className="measurement-unit-select"
              size="small"
              defaultValue={"select"}
              value={measureUnitSelection ?? "select"}
              onChange={(value: string): void => {
                handleMeasureUnitChange(value);
              }}
              options={measureUnitOptions}
            />
          </span>
        </div>
        {globalDensityRange ? (
          <DensityRangeComponent
            globalDensityRange={globalDensityRange}
            globalDensityValues={globalDensityValues}
            updateProductDensityRange={updateProductDensityRange}
          />
        ) : (
          <></>
        )}
        <br />

        {!hasSelected ? (
          <div className="drawer-newitem">
            <img src={boxIcon} />
            <p className="text-xl text-semibold mb-8">
              {formatTranslation(
                t("createProtocol.products.noProductSelected")
              )}
            </p>
            <p className="">
              {formatTranslation(t("createProtocol.products.paragraph"))}
            </p>
          </div>
        ) : (
          <div>
            {selectedProducts.map((product) => {
              return (
                <Flex vertical key={product.id}>
                  <ProductCard
                    id={product.id}
                    key={product.key}
                    updateProductDensityRange={updateProductDensityRange}
                    onDeleteProduct={onDeleteProduct}
                    productKey={product.key}
                    globalDensityRange={globalDensityRange}
                    variety={product.variety}
                    manufacturer={product.manufacturer}
                    measure_unit={product.measure_unit}
                    productDensityRangeMin={product.density_range?.min}
                    productDensityRangeMax={product.density_range?.max}
                  />
                </Flex>
              );
            })}
          </div>
        )}
        <Drawer
          className="objective-drawer"
          rootClassName="objective-drawer"
          title="Objective information"
          onClose={closeDrawer}
          open={isDrawerOpen}
        >
          <h2 className="objective-drawer-name">Product Details</h2>
          <div>
            <h3>SY KingsBarn</h3>
            <br />
            <div>Variety Overview</div>
            <div>
              <strong>End-use group: </strong> Six-row feed, Hybrid
            </div>
            <div>
              <strong>Market options: </strong> Livestock Feed
            </div>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default ProductsComponent;
