import monitorsList from "./monitors.json";

export interface MonitorModel {
  /** Human-readable identifier for a specific monitor model, used for assembling lists of allowed monitors per country */
  id: string;
  /** True value indicates the monitor rate must be in whole numbers, false value indicates it can be in decimal values */
  wholeNumber: boolean;
  /** should only be present if wholeNumber is false - specifies the maximum number of decimals allowed for this monitor model */
  numberOfDecimals?: number;
  /** list of extra attribute titles that need to be applied to geojson */
  attributes?: string[];
  format: string;
  label?: string;
  monitor: string;
  /** true indicates only one seeding rate for one product can be included in a shapefile, false or undefined indicates seeding rates for multiple products can be included in one shapefile */
  singleProduct?: boolean;
  linear: boolean;
  units: string;
  /** optional override of default column names for nitrogen application, assigned in order */
  NitrogenColumnNames?: string[];
  /** set to true if nitrogen columns must be included in the work order, even if no nitrogen was applied */
  NitrogenColumnsRequired?: boolean;
  /** optional override of default rate column name; only applicable if singleProduct is true */
  ProductColumnName?: string;
}

export interface MonitorBrand {
  format: string;
  label: string;
}

export const MonitorBrands: MonitorBrand[] = monitorsList.brands;
export const MonitorModels: MonitorModel[] = monitorsList.monitors;
