/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Suspense } from "react";
import { Layout } from "syngenta-digital-cropwise-react-ui-kit";
import { ErrorBoundary } from "./shared/components/ErrorBoundary";
import { Loader } from "./shared/components/Loader";
import { GlobalHeader } from "./shared/layout/GlobalHeader";
import { SidebarMenu } from "./shared/layout/Sidebar";
import { ThemeProvider } from "./shared/layout/ThemeProvider";
import "./index.less";
import { AppRoutes } from "./pages/index";
import { AppContext } from "./lib/appContext/AppContext";

const { Content } = Layout;

function App(): JSX.Element | null {
  return (
    <ErrorBoundary>
      <ThemeProvider>
        <AppContext>
          <div className="app-root">
            <GlobalHeader />
            <Layout className="cw-page-container">
              <Suspense fallback={<Loader mask={true} message="Loading..." />}>
                <SidebarMenu />
                <Layout className="main-layout">
                  <Content className="main-content">
                    <AppRoutes />
                  </Content>
                </Layout>
              </Suspense>
            </Layout>
          </div>
        </AppContext>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
