import { useEffect, useState } from "react";
import { type OrganizationDataType } from "./types";

export const useFilteredOrganisations = ({
  response,
  searchText,
}: {
  response: { content: OrganizationDataType[] };
  searchText: string;
}): OrganizationDataType[] => {
  const [filteredOrganisations, setFilteredOrganisations] = useState<
    OrganizationDataType[]
  >([]);

  useEffect(() => {
    const filteredOrgs = response?.content?.filter(
      (org: OrganizationDataType) =>
        org.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredOrganisations(filteredOrgs);
  }, [searchText, response]);

  return filteredOrganisations;
};
