import { type FC } from "react";
import { Card } from "syngenta-digital-cropwise-react-ui-kit";
import "./orgcard.less";
import { type orgCardParams } from "../types";

export const OrgCard: FC<orgCardParams> = ({
  disabled = false,
  text = "",
  initials = "",
  image = "",
  id = "",
  onClick,
}) => {
  const showInitials = image === "" && initials !== "";
  const getInitialsFromText = (text: string): string => {
    const words = text.split(" ")
    if (words.length >= 2) {
      const firstIntials = words[0].charAt(0).toUpperCase();
      const secontIntials = words[1].charAt(0).toUpperCase();
      return `${firstIntials}${secontIntials}`;
    }
    return ""
  }

  const handleClick = (): void => {
    if (onClick) {
      onClick(id);
    }
  };
  return (
    <Card
      data-testid="org-card"
      className={"org-card " + (disabled ? "disabled" : "")}
      onClick={disabled ? () => { } : handleClick}
    >
      <div className="org-card-image-container">
        {showInitials ? (
          <div
            className="org-card-image"
            data-testid="org-card-image-container"
          >
            <img src={image} />
          </div>
        ) : (
          <div data-testid="org-card-initials" className="org-card-initials">
            {getInitialsFromText(text)}
          </div>
        )}
      </div>
      <div className="org-card-text">{text}</div>
    </Card>
  );
};