import { ConfigProvider } from "antd";
import React, { type ReactNode } from "react";
import { AntdConfigProvider } from "syngenta-digital-cropwise-react-ui-kit";
interface IThemeProviderProps {
  themeName?: string;
  children: ReactNode;
}
export const ThemeProvider: React.FC<IThemeProviderProps> = ({
  children,
  themeName,
}) => (
  <AntdConfigProvider prefixCls="syngenta-ant">
    <ConfigProvider
      theme={{
        components: {
          Spin: {
            colorPrimary: "rgba(20, 128, 60, 1)",
            colorBgElevated: "#ffffff",
            zIndexPopupBase: 1000,
            colorBgMask: "rgba(20, 21, 28, .75)",
          },
          Tag: {
            fontSizeSM: 10,
          },
          Table: {
            headerBg: "rgba(223, 226, 231, 1)",
            headerBorderRadius: 0,
            fontSize: 14,
            borderColor: "rgba(194, 199, 208, 1)",
          },
          Pagination: {
            itemActiveBg: "rgba(234, 246, 255, 1)",
            colorBorder: "red",
            colorText: "rgba(105, 111, 136, 1)",
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  </AntdConfigProvider>
);
