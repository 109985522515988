import { type ReactElement } from "react";
import { Input } from "antd";
import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import "./search.less";

export const Search = ({
  searchText,
  setSearchTexts,
  className,
}: {
  searchText: string;
  setSearchTexts: (text: string) => void;
  className?: string;
}): ReactElement => {
  return (
    <Input
      className={` ${className ?? " "} search-input `}
      value={searchText}
      prefix={<SearchOutlined className="site-form-item-icon" />}
      suffix={
        searchText && (
          <div
            className="suffix-icon"
            onClick={() => {
              setSearchTexts("");
            }}
          >
            <CloseCircleOutlined data-testid="clear-icon" />
          </div>
        )
      }
      onChange={(e) => {
        setSearchTexts(e.target.value);
      }}
      placeholder={"Search"}
    />
  );
};
