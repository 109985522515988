import React, { type ReactElement } from "react";
import "./style.less";
import {
  type protocolStatusType,
  type protocolType,
} from "../../../pages/protocols/types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";

interface LabelTagType {
  text?: string;
  type?: protocolStatusType | protocolType | "disabled" | "more";
  className?: string | undefined | null;
}
// enum for types
// use <Label = ENUM.DRAFT = "Draft"
// use className to uppercase ENUM.var
export default function LabelTag({
  text,
  className,
  type,
}: LabelTagType): ReactElement {
  const { t } = useTranslation();

  if (type === "Draft") {
    return <div className="labeltag label-draft">{formatTranslation(t("trialtypes.label.comingSoon"))}</div>;
  }
  if (type === "Published") {
    return <div className="labeltag label-published">{formatTranslation(t("trialtypes.label.published"))}</div>;
  }
  if (type === "seed") {
    return <div className="labeltag label-published">{formatTranslation(t("trialtypes.label.seed"))}</div>;
  }
  if (type === "crop-protection") {
    return <div className="labeltag label-published">{formatTranslation(t("trialtypes.label.cropProtection"))}</div>;
  }
  if (type === "fertiliser") {
    return <div className="labeltag label-published">{formatTranslation(t("trialtypes.label.fertiliser"))}</div>;
  }
  if (type === "disabled") {
    return (
      <div className={`${className ?? " "} labeltag label-disabled`}>
        {" "}
        {text}{" "}
      </div>
    );
  }
  return <></>;
}
