import { type FC } from "react";
import { type DeletePlotModalProps } from "../types";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

export const DeletePlotModal: FC<DeletePlotModalProps> = ({
  dataKey,
  data,
  isModalOpen,
  setIsModalOpen,
  onDelete,
}) => {
  const checkReplicantData =
    data !== null &&
    data !== undefined &&
    Object.prototype.hasOwnProperty.call(data, "parentId");

  const handleDelete = (): void => {
    onDelete(data, dataKey, checkReplicantData ? "replicantKey" : "");
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  const handleCancel = (): void => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      className="delete-plot-modal"
      footer=""
    >
      <div className="title-sec">
        <ExclamationCircleOutlined data-testid="exclamation-icon" />
        <div>
          <h3 className="modal-title">
            {formatTranslation(t("newTrial.deletePlotModel.deleteButton"))}{" "}
            {data?.name} ?
          </h3>
          <p className="modal-desc">
            {formatTranslation(t("newTrial.deletePlotModel.conformationText"))}{" "}
            {data?.name} ?
          </p>
        </div>
      </div>
      <div className="btn-sec">
        <Button onClick={handleCancel}>
          {formatTranslation(t("createProtocol.objectives.cancelButton"))}
        </Button>
        <Button loading={false} onClick={handleDelete}>
          {formatTranslation(t("newTrial.deletePlotModel.deleteButton"))}
        </Button>
      </div>
    </Modal>
  );
};
