import { type ReactElement } from "react";
import type { ProductCardProps } from "../interfaces/product-interfaces";
import { Flex, Typography } from "antd";
import { DeleteButton } from "../../../../shared/layout/DeleteButton";
import { DensityRangeComponent } from "./DensityRangeComponent";

export const ProductCard = ({
  globalDensityRange,
  variety,
  manufacturer,
  productKey,
  id,
  onDeleteProduct,
  updateProductDensityRange,
  productDensityRangeMin,
  productDensityRangeMax,
}: ProductCardProps): ReactElement => {
  return (
    <div className="product-card">
      <Flex vertical={false} justify="space-between">
        <div>
          <Typography>
            {" "}
            <strong>{variety}</strong>{" "}
          </Typography>
          <Typography>{manufacturer}</Typography>
        </div>
        <div>
          <DeleteButton
            onClick={() => {
              onDeleteProduct(productKey);
            }}
          ></DeleteButton>
        </div>
      </Flex>
      {!globalDensityRange ? (
        <DensityRangeComponent
          globalDensityRange={globalDensityRange}
          productKey={productKey}
          updateProductDensityRange={updateProductDensityRange}
          productDensityRangeMin={productDensityRangeMin}
          productDensityRangeMax={productDensityRangeMax}
          productId={id}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
