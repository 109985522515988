import { useEffect, useState, type ReactElement } from "react";
import { Card } from "antd";
import "../style.less";
import cpIcon from "../../../../src/assets/images/cp-icon.svg";
import fertilizerIcon from "../../../../src/assets/images/fertilizer-icon.svg";
import { useCreateProtocolForm } from "..";
import seedsIcon from "../../../../src/assets/images/seed-icon-green.svg";
import seedsIconDisabled from "../../../../src/assets/images/seed-icon.svg";
import LabelTag from "../../../shared/components/LabelTag";
import { type protocolType } from "../../protocols/types";
import { useSearchParams } from "react-router-dom";
import AxiosInstance from "../../../shared/utils/axios";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../shared/utils/translationUtils";

type trialTypes = protocolType | null | string;

interface TrialTypeCardTypes {
  disabled?: boolean;
  selected?: boolean;
  icon?: any;
  iconDisabled?: any;
  heading: any;
  description: string;
  trialType: trialTypes;
  t?: any;
}

const TrialTypesComponent = (): ReactElement => {
  const { t } = useTranslation();
  const { form, formValidationData, setFormValidationData } =
    useCreateProtocolForm();
  const trialTypesData = [
    {
      disabled: false,
      icon: seedsIcon,
      iconDisabled: seedsIconDisabled,
      heading: "Seed",
      description: "Description if needed",
      trialType: "seed",
    },
    {
      disabled: true,
      iconDisabled: cpIcon,
      heading: "Crop Protection",
      description: "Description if needed",
      trialType: "fertiliser",
    },
    {
      disabled: true,
      iconDisabled: fertilizerIcon,
      heading: "Fertiliser",
      description: "Description if needed",
      trialType: "crop-protection",
    },
  ];

  const [searchParams] = useSearchParams();
  const protocolId = searchParams.get("protocol_id");
  const [existingData, setExistingData] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<trialTypes>(null);

  const getProtocolData = async (): Promise<any> => {
    try {
      const response = await AxiosInstance.get(
        `/protocol/${protocolId as string}`
      );
      setExistingData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (protocolId) {
      getProtocolData().catch((error) => {
        console.error("Error fetching data:", error);
      });
    }
  }, [protocolId]);

  useEffect(() => {
    if (existingData) {
      const type = existingData.protocol_type
        ? existingData.protocol_type[0]
        : "";

      setSelectedType(type);
      form.setFieldsValue({
        protocolType: [type],
      });
      setFormValidationData({ ...formValidationData, protocolType: type });
    }
  }, [existingData]);

  // todo : allow selection of multiple types

  const handleSelection = (selectedType: trialTypes): void => {
    setSelectedType(selectedType);
    form.setFieldsValue({ protocolType: [selectedType] });
    setFormValidationData({
      ...formValidationData,
      protocolType: selectedType,
    });
  };

  return (
    <div className="trialTypes-component">
      <h2>{formatTranslation(t("createProtocol.trialType.title"))}</h2>
      <p>{formatTranslation(t("createProtocol.trialType.description"))}</p>
      <br />

      <div className="trialTypes-cards-container">
        {trialTypesData.map((cardData: TrialTypeCardTypes, index: number) => (
          <div
            key={index}
            onClick={() => {
              handleSelection(cardData.trialType);
            }}
          >
            <TrialTypeCard
              disabled={cardData.disabled}
              icon={cardData.icon}
              iconDisabled={cardData.iconDisabled}
              description={cardData.description}
              heading={cardData.heading}
              selected={cardData.trialType === selectedType}
              trialType={cardData.trialType}
              t={t}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const TrialTypeCard = ({
  disabled,
  icon,
  iconDisabled,
  heading,
  description,
  selected,
  trialType, t,
}: TrialTypeCardTypes): ReactElement => {
  return (
    <Card
      hoverable={!disabled}
      className={`${!disabled && selected ? "active trialTypes-card  " : "trialTypes-card "
        }`}
    >
      {disabled && <div className="backdrop"></div>}
      {disabled && <LabelTag text={formatTranslation(t("trialtypes.label.comingSoon"))} type="disabled" />}
      {/* <LabelTag type="Draft" className="disabled-label"  /> */}
      <div>
        {!disabled && selected === true ? (
          <img src={icon}></img>
        ) : (
          <img src={iconDisabled}></img>
        )}
      </div>

      {/* <SeedIcon selected = {selected} disabled={disabled} ></SeedIcon> */}

      <p className="trailTypes-card-heading ">{heading}</p>
      <p>{description}</p>
    </Card>
  );
};

export default TrialTypesComponent;
