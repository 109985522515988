import { useState, type ReactElement } from "react";
import { Search } from "../../shared/layout/Search";
import { type HallOfFarmesPropertiesResp } from "./types";
import useAxios from "../../shared/custom-hooks/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import leftIcon from "../../assets/images/left-arr.svg";
import infoIcon from "../../assets/images/info.svg";
import { PrimaryButton } from "../../shared/layout/PrimaryButton";
import PropertyFarmCard from "./PropertyFarmCard";
import noSearch from "../../assets/images/noSearch.svg";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { type FarmValueInterface } from "../../lib/appContext/type";
import { useAppState } from "../../lib/appContext/AppContext";
import { Loader } from "../../shared/components/Loader";
import { route } from "../constant";
import { formatTranslation } from "../../shared/utils/translationUtils";

const HallOfFarms = (): ReactElement => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const id: string = location.state?.id;
  const { stateValue, setStateValue } = useAppState();

  const { response: propertiesResp, loading } = useAxios(
    `/${route.ORGS.path}/${id}/properties`,
    true
  );

  const newFarmUrl = process.env.REACT_APP_CROPWISE;
  const callBackTrialUrl = process.env.REACT_APP_CALLBACK_FARMS;

  const onNavigate = (): void => {
    navigate(`/app/${route.ORGS.path}`);
  };

  const filteredProperties = propertiesResp?.content?.filter(
    (property: HallOfFarmesPropertiesResp) => {
      const isMatch = property.name
        .toLowerCase()
        .includes(searchText.toLowerCase());

      return isMatch;
    }
  );

  const handleFarmValue = (val: FarmValueInterface): void => {
    setStateValue({
      ...stateValue,
      farmValue: val,
    });
    navigate(`/app/${route.TRIALS.path}`);
  };

  const setUrl = (propertyId: string): void => {
    const editFarmURL = `${newFarmUrl ?? ""}/create-property?org_id=${
      id ?? ""
    }&property_id=${propertyId ?? ""}&callback_uri=${callBackTrialUrl ?? ""}`;

    window.location.assign(editFarmURL);
  };
  // const isSearchPerformed = searchText.trim() !== "";

  return (
    <div className="hall-of-org-main hall-of-farmes">
      <div className="header-row">
        <div className="card_title">
          <Button
            className="Left-button-icon"
            data-testid="left-navigate"
            onClick={onNavigate}
          >
            <img src={leftIcon} alt="left-icon" />
          </Button>
          <h2>{formatTranslation(t("farms.title"))}</h2>
          <img src={infoIcon} alt="info-icon" className="info-icon" />
          <Search searchText={searchText} setSearchTexts={setSearchText} />
        </div>
        <PrimaryButton
          className="farm-btn"
          onClick={() => {
            setUrl("");
          }}
        >
          <PlusOutlined /> {formatTranslation(t("farms.newFarmButton"))}
        </PrimaryButton>
      </div>
      {!(filteredProperties?.length === 0) ? (
        <div className="section-main">
          <p className="title">{formatTranslation(t("farms.farmList"))}</p>
          {loading ? (
            <Loader mask />
          ) : (
            <div className="card-main">
              {filteredProperties?.map(
                (farm: FarmValueInterface, index: number) => (
                  <div
                    key={index}
                    className="get-button"
                    onClick={() => {
                      handleFarmValue(farm);
                    }}
                  >
                    <PropertyFarmCard property={farm} />
                  </div>
                )
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="no-result-data">
          <p>{formatTranslation(t("farms.noSwearchData"))}</p>
          <img src={noSearch} alt="noSearch" />
        </div>
      )}
    </div>
  );
};

export default HallOfFarms;
