import { type FC } from "react";
import { Card, Button } from "syngenta-digital-cropwise-react-ui-kit";
import "./farmCard.less";
import { type farmCardParams } from "../types";
import starUnselected from "../../../assets/images/star-unselected.svg";
import areaIcon from "../../../assets/images/area-icon.svg";
import rightIcon from "../../../assets/images/right-arr.svg";
import cropCycle from "../../../assets/images/crop-cycle.svg";
import crop from "../../../assets/images/crop.svg";
import editField from "../../../assets/images/edit-field.svg";
import history from "../../../assets/images/history.svg";
import CropCycleEdit from "../../../assets/images/crop-cycle-edit.svg";
import { calculateFieldsInfo } from "../../../pages/hall-of-farms/utils";

export const FarmCard: FC<farmCardParams> = ({
  id,
  disabled = false,
  farmName = "",
  image = "",
  farmDetail,
  cyclesDetail,
  orgId,
}) => {
  const { total, numberOfFields } = calculateFieldsInfo(farmDetail?.content);
  const editFarm = process.env.REACT_APP_CROPWISE;
  const callbackEdittrial = process.env.REACT_APP_CALLBACK_FARMS;
  const numberOfCycle = cyclesDetail?.content?.length;

  const formattedFields =
    numberOfFields > 0 ? `${numberOfFields} fields • ${total} ha` : "No fields";
  const formattedCycle = numberOfCycle > 0 ? "Crop cycles" : "No crop cycles";
  const editFarmUrl = (): void => {
    window.location.assign(
      `${editFarm as string}/edit-property?org_id=${
        orgId as string
      }&property_id=${id as string}&callback_uri=${callbackEdittrial ?? ""}`
    );
  };
  return (
    <Card
      data-testid="farm-card"
      className={"farm-card" + (disabled ? " disabled" : "")}
    >
      <div className="first-field" data-testid="first-field">
        <div className="farm-star">
          <img src={starUnselected} alt="starUnselected" />
        </div>
        <div className="farm-text">
          {farmName}
          <img src={rightIcon} alt="rightIcon" />
        </div>
      </div>
      <div className="farm-text second-section">
        <img src={cropCycle} alt="cropCycle" />
        <h3>{formattedCycle}:</h3>
        <img src={crop} alt="crop" />
      </div>
      <div className="farm-text third-section">
        <img src={areaIcon} alt="areaIcon" data-testid="farm-card-image" />
        {formattedFields}
      </div>
      <div className="last-elem">
        <div className="color-box">
          <img src={editField} alt="editField" />
        </div>
        <div className="color-box">
          <img src={history} alt="history" />
        </div>
        <div className="color-box">
          <img src={CropCycleEdit} alt="CropCycleEdit" />
        </div>
        <Button
          className="edit-btn"
          onClick={() => {
            editFarmUrl();
          }}
        >
          Edit
        </Button>
      </div>
    </Card>
  );
};
