/* eslint-disable @typescript-eslint/explicit-function-return-type */
import axios from "axios";

const token = (): string => localStorage.getItem("tokens") ?? sessionStorage.getItem("tokens") ?? "";
export const getAccessToken = (): string => {
  try {
    return JSON.parse(token()).access_token;
  } catch (e) {
    return "";
  }
};

const UNAUTHORIZED_ERROR_CODE = 401;

const URL = process.env.REACT_APP_BASE_API_URL ?? "";
const version = process.env.REACT_APP_BASE_API_VERSION ?? "";

const baseURL = `${URL}/${version}`;

export const WorkorderDownloadAPICall = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`,
    API_CONSUMER: "TRIALING"
  },
  responseType: "blob",
});

export const PlatFormAPIAxiosInstance = axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
    Authorization: `Bearer ${getAccessToken()}`,
  },
});

PlatFormAPIAxiosInstance.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getAccessToken()}`;
    return config;
  },
  async function (error) {
    return await Promise.reject(error);
  }
);
PlatFormAPIAxiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === UNAUTHORIZED_ERROR_CODE) {
      //   authService.getAccessToken();
      // void logout();
    }
  }
);

export default PlatFormAPIAxiosInstance;
