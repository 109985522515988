import { useEffect, useState, type FC } from "react";
import {
  type BufferZonePopupProps,
  type BufferZone,
} from "../../../../../pages/new-trail/types";
import { InputNumber, Popover } from "antd";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { usePlotCardState } from "../../utils";
import useFormHandling from "../../../../../pages/new-trail/hooks/useFormHandling";
import { CloseOutlined } from "@ant-design/icons";
import { useNewTrialState } from "../../../../../pages/new-trail/hooks/NewTrialStateProvider";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../utils/translationUtils";

export const BufferZonePopup: FC<BufferZonePopupProps> = ({
  openPopoverKey,
  onHandleData,
  children,
  val = 0,
  trialData,
}) => {
  const { popoverState, setPopoverState } = usePlotCardState();
  const [value, setValue] = useState<number>(10);
  const { form } = useFormHandling();
  const { newTrialState } = useNewTrialState();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    if (id && trialData?.trialData.buffer_zone) {
      setValue(trialData?.trialData.buffer_zone.size);
    }
  });

  const getData = (): BufferZone | undefined => {
    const getObjectData = newTrialState.buffer_zone?.find(
      (zoneData: BufferZone) => zoneData.id === val
    );
    return getObjectData;
  };

  const handleOpenChange = (key: string): void => {
    setPopoverState((prevState: Record<string, number | null>) => ({
      ...prevState,
      [key]: val,
    }));
    if (openPopoverKey === "openEditPopover") {
      const dataSize = getData()?.size;
      if (dataSize !== undefined) {
        setValue(dataSize);
      }
    }
  };

  const handleApplydata = (): void => {
    if (openPopoverKey === "openPopover") {
      onHandleData(value);
    } else {
      onHandleData(value, openPopoverKey, val);
    }
    setPopoverState((prevState: Record<string, number | null>) => ({
      ...prevState,
      openPopover: null,
      openEditPopover: null,
    }));
  };

  const handleClosePopover = (): void => {
    setValue(10);
    setPopoverState((prevState: Record<string, number | null>) => ({
      ...prevState,
      openPopover: null,
      openEditPopover: null,
    }));
  };

  const titleContent = (
    <div className="popover-title">
      <span>Buffer Zone</span>
      {openPopoverKey === "openPopover" && (
        <Button className="close-btn" onClick={handleClosePopover}>
          <CloseOutlined />
        </Button>
      )}
    </div>
  );

  const content = (
    <div className="buffer-zone-popup">
      <p className="desc">
        {formatTranslation(t("trials.plotDesign.bufferZone.popUp"))}
      </p>
      <div className="zone-input-sec">
        <label htmlFor="Buffer zone size (m)*">{formatTranslation(t("trials.plotDesign.bufferZoneSize"))}</label>
        <div className="input-main">
          <Button
            onClick={() => {
              setValue(value - 10);
            }}
          >
            -
          </Button>
          <InputNumber
            data-testid="input-val"
            value={value}
            controls={false}
            onChange={(value: any) => {
              form.setFieldsValue({
                buffer_zone: {
                  size: value,
                },
              });
              setValue(value);
            }}
          />
          <Button
            onClick={() => {
              setValue(value + 10);
            }}
          >
            +
          </Button>
        </div>
      </div>
      <div className="edit-btns-popover">
        {openPopoverKey !== "openPopover" && (
          <Button className="apply-btn-cancel" onClick={handleClosePopover}>
            {formatTranslation(t("createProtocol.objectives.cancelButton"))}
          </Button>
        )}
        <Button className="apply-btn-edit" onClick={handleApplydata}>
          Apply {openPopoverKey === "openPopover" ? "Buffer Zone" : ""}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {popoverState[openPopoverKey] === val && (
        <Button
          onClick={handleClosePopover}
          className="popover-backdrop"
        ></Button>
      )}
      <Popover
        content={content}
        title={titleContent}
        placement="right"
        trigger={"click"}
        open={popoverState[openPopoverKey] === val}
        onOpenChange={() => {
          handleOpenChange(openPopoverKey);
        }}
      >
        {children}
      </Popover>
    </>
  );
};
