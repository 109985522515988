export const addRatesDosagesToPlotProperties = (trialData: any): any => {
  const updatedTrialData = { ...trialData };

  updatedTrialData?.trial_plots?.forEach((plot: any, index: number) => {
    const measureUnit = updatedTrialData?.rates_and_dosages?.unit_of_rate;
    const properties =
      updatedTrialData?.rates_and_dosages?.rates_and_dosages[index];
    const updatedProperties = properties?.map((property: any) => {
      return {
        variety: property.variety,
        rate: property.rate,
        measure_unit: measureUnit,
      };
    });
    plot.plot.plot_id = plot.plot.id;
    plot.properties = {
      seeds: {
        measure_unit: measureUnit,
        rates_and_dosages: updatedProperties,
      },
    };
  });
  return updatedTrialData;
};
