import React, { createContext, useContext, useState } from "react";
import {
  type AppContextInterface,
  type AppInitialContextInterface,
} from "./type";

const AppStateProvider = createContext<AppContextInterface | null>(null);

export const AppContext = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [stateValue, setStateValue] = useState<AppInitialContextInterface>({
    orgsData: null,
    orgValue: null,
    farmValue: null,
    assigneeValue: null,
  });

  return (
    <AppStateProvider.Provider value={{ stateValue, setStateValue }}>
      {children}
    </AppStateProvider.Provider>
  );
};

export const useAppState = (): AppContextInterface => {
  const context = useContext(AppStateProvider);
  if (!context) {
    throw new Error("useAppState must be used within a AppContextProvider");
  }
  return context;
};
