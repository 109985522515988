import { colorMatrix } from "../../../maps/components/MapComponent/constants";

export const getCircleColor = (
  variety: string,
  rate: number,
  trialProducts: any,
  trialMeasureUnit?: string
): string => {
  let idx = -1;
  if (trialProducts) {
    idx = trialProducts.map((x: any) => x.variety).indexOf(variety);
    let convertedRate: number = rate;
    if (trialMeasureUnit === "seeds/m2") {
      convertedRate = rate * 10000;
    }
    if (idx !== -1) {
      return getColorFromShadeMap(colorMatrix[idx], convertedRate);
    }
  }
  return "";
};

const getColorFromShadeMap = (shadeMap: any, rate: number): string => {
  let color = "";
  Object.keys(shadeMap).forEach((thresholdStr) => {
    const threshold = parseInt(thresholdStr);
    if (rate === 0) {
      return color;
    }
    if (rate < threshold && !color) {
      color = shadeMap[threshold];
    }
  });
  return color;
};
