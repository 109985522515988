import { type FC } from "react";
import "./fieldCard.less";
import { type FieldCardTypes } from "../types";
import { Radio } from "syngenta-digital-cropwise-react-ui-kit";
import fieldImg1 from "../../../assets/images/field-img-1.svg";
import { FieldThumbnail } from "./FieldThumbnail";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";

export const FieldCard: FC<FieldCardTypes> = ({
  fieldName,
  id,
  image,
  onChange,
  geojson,
}) => {
  const { trialInfo } = useNewTrialState();

  return (
    <div
      className={`radio-card-main ${trialInfo?.field_ids[0] === id ? "selected-field" : ""
        }`}
    >
      <Radio.Group onChange={onChange} value={trialInfo?.field_ids[0]}>
        <Radio value={id} data-testid="radio-btn" className="radio-btn" />
      </Radio.Group>
      <div className="img-section">
        {image ? (
          <img src={image} alt="field-img" />
        ) : (
          geojson && <FieldThumbnail geojson={geojson} style={{}} />
        )}
      </div>
      <div className="details">
        <h3 data-testid={fieldName}>{fieldName}</h3>
      </div>
      <Radio.Group onChange={onChange} value={trialInfo?.field_ids[0]}>
        <Radio value={id} data-testid="radio-div" className="radio-div" />
      </Radio.Group>
    </div>
  );
};
