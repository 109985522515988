import {
  feature,
  featureCollection,
  type Feature,
  type FeatureCollection,
  type MultiPolygon,
  type Polygon,
} from "@turf/helpers";
import { useMemo } from "react";
import geojson2svg from "geojson-to-svg";
import proj4 from "proj4";

type SupportedGeometryTypes = Polygon | MultiPolygon;
export type SupportedGeoJsonTypes =
  | SupportedGeometryTypes
  | Feature<SupportedGeometryTypes>
  | FeatureCollection<SupportedGeometryTypes>;

const projWgs84TobMercator = proj4("WGS84", "GOOGLE");

/**
 * Some type guards to help handle the various inputs we support.
 */

const isFeatureCollection = (
  geojson: SupportedGeoJsonTypes
): geojson is FeatureCollection<SupportedGeometryTypes> => {
  return (
    (geojson as FeatureCollection<SupportedGeometryTypes>).type ===
    "FeatureCollection"
  );
};

const isFeature = (
  geojson: SupportedGeoJsonTypes
): geojson is Feature<SupportedGeometryTypes> => {
  return (geojson as Feature<SupportedGeometryTypes>).type === "Feature";
};

const isGeometry = (
  geojson: SupportedGeoJsonTypes
): geojson is SupportedGeometryTypes => {
  return (
    !isFeatureCollection(geojson) &&
    !isFeature(geojson) &&
    geojson.coordinates?.length > 0
  );
};

/**
 * A component to take a geojson and create a thumbnail to represent it on a plain background.
 */
export const FieldThumbnail = ({
  geojson,
  style,
}: {
  geojson: SupportedGeoJsonTypes;
  style: React.SVGAttributes<boolean>;
}): JSX.Element => {
  // Allow the caller to customize the style.  This is across the whole geometry, there's no sub-element customization support
  const renderProperties = useMemo(() => {
    return {
      fill: "#C2C7D0",
      stroke: "#C2C7D0",
      weight: 0.00009,
      width: "20px",
      height: "20px",
      ...style,
    };
  }, [style]);

  // Whatever was given to us, we need to end up with a feature collection.
  const collection: FeatureCollection<SupportedGeometryTypes> = useMemo(() => {
    if (isGeometry(geojson)) {
      return featureCollection([feature(geojson, renderProperties)]);
    } else if (isFeature(geojson)) {
      return featureCollection([feature(geojson.geometry, renderProperties)]);
    } else if (isFeatureCollection(geojson)) {
      return featureCollection(
        geojson.features.map((f) =>
          feature(f.geometry, {
            ...renderProperties,
            fill: f?.properties?.color,
          })
        )
      );
    } else {
      throw new Error(
        `Unexpected geojson type passed to FieldThumbnail: ${String(
          (geojson as any).type
        )}`
      );
    }
  }, [geojson, renderProperties]);

  // Project to WebMercator and transform to SVG
  const image = useMemo(
    () =>
      geojson2svg()
        .projection((coord: [number, number]) => {
          return projWgs84TobMercator.forward(coord);
        })
        .type("thumb")
        .styles({ basic: renderProperties })
        .data(collection)
        .render(),
    [collection, renderProperties]
  );

  return (
    <img
      alt="Field Thumbnail"
      width={renderProperties.width}
      height={renderProperties.height}
      style={{ transform: "scaleY(-1)" }}
      src={`data:image/svg+xml;base64,${btoa(image)}`}
    />
  );
};
