import { route } from "../constant";
import { type objectivesTargetType, type ProtocolStepItemType } from "./types";

export const protocolsSteps: ProtocolStepItemType[] = [
  {
    title: "Trial Type",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_TRIALTYPE.path}`,
    stepNumber: 1,
  },
  {
    title: "Basic Information",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_BASICINFO.path}`,
    stepNumber: 2,
  },
  {
    title: "Objectives",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_OBJECTIVES.path}`,
    stepNumber: 3,
  },
  {
    title: "Products",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_PRODUCTS.path}`,
    stepNumber: 4,
  },
  {
    title: "Summary",
    path: `/app/${route.PROTOCOLS.path}/${route.PROTOCOLS_SUMMARY.path}`,
    stepNumber: 5,
  },
];

export const SUBMIT_PROTOCOL_LABEL = "Save and configure trial";
export const targets: objectivesTargetType[] = [
  {
    label: "Yield",
    value: "Yield",
  },
  {
    label: "Moisture",
    value: "Moisture",
  },
  {
    label: "Peak NDVI",
    value: "Peak NDVI",
  },
  {
    label: "Dry Matter",
    value: "Dry Matter",
  },
  // {
  //   label: "Seed Rate",
  //   value: "seed_rate",
  // },
  // {
  //   label: "Sunflower Rate",
  //   value: "sunflower_rate",
  // },
  // {
  //   label: "Silage Rate",
  //   value: "silage rate",
  // },
  // {
  //   label: "Cereals Rate",
  //   value: "cereals rate",
  // },
  // {
  //   label: "Fert Rate",
  //   value: "fert rate",
  // },
];

export const dummyObjectives = [
  {
    name: "First Objective",
    target: "Crop Establishment",
    description: "This is a test description for the first objective",
  },
  {
    name: " Objective 2",
    target: "Crop Establishment",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
  },
];

export const cropOptionsObject = [
  {
    value: "corn",
    label: "Corn",
    emoji: "img",
    desc: "(corn)",
  },
  {
    value: "sunflower",
    label: "Sunflower",
    emoji: "img",
    desc: "(sunflower)",
  },
];

export const measureUnitOptions = [
  {
    value: "seeds/m2",
    label: (
      <>
        seeds/m<sup>2</sup>
      </>
    ),
  },
  {
    value: "seeds/ha",
    label: <>seeds/ha</>,
  },
];
