import { type FC } from "react";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { Tooltip, Popconfirm } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { type DeleteIconProps } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import "./deleteButton.less";

export const DeleteButton: FC<DeleteIconProps> = ({
  onClick,
  children,
  protocolName,
  disabled,
}) => {
  const { t } = useTranslation();
  let tootTipText;
  disabled === true
    ? (tootTipText = (
        <span>
          {formatTranslation(t("protocols.deleteDisableButtonTooltipText"))}
        </span>
      ))
    : (tootTipText = (
        <span>{formatTranslation(t("protocols.deleteButtonTooltipText"))}</span>
      ));

  return (
    <>
      {disabled === true ? (
        <Tooltip placement="left" title={tootTipText}>
          <Button className="delete-button-disabled" disabled>
            {children}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip placement="left" title={tootTipText}>
          <Popconfirm
            rootClassName="protocol-delete-popup"
            placement="topRight"
            title={`Delete the ${protocolName as string}?`}
            description={
              <span className="del-label">
                {formatTranslation(t("trials.deleteText"))}
              </span>
            }
            icon={<QuestionCircleOutlined />}
            onConfirm={onClick}
          >
            <Button className="delete-button"></Button>
            {children}
          </Popconfirm>
        </Tooltip>
      )}
    </>
  );
};
