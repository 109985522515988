import { type FC } from "react";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { Tooltip } from "antd";
import { type Props } from "../types";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";
import "./editButton.less";

export const EditButton: FC<Props> = ({ onClick, disabled, children }) => {
  const { t } = useTranslation();
  let tootTipText;
  disabled === true
    ? (tootTipText = (
        <span>
          {formatTranslation(t("protocols.editDisableButtonTooltipText"))}
        </span>
      ))
    : (tootTipText = (
        <span>{formatTranslation(t("protocols.editButtonTooltipText"))}</span>
      ));

  return (
    <>
      {disabled === true ? (
        <Tooltip placement="left" title={tootTipText}>
          <Button className="edit-button-disabled" onClick={onClick} disabled>
            {children}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip placement="left" title={tootTipText}>
          <Button className="edit-button" onClick={onClick}>
            {children}
          </Button>
        </Tooltip>
      )}
    </>
  );
};
