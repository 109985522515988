import { useState, type ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OrgCard } from "../../shared/layout/OrgCard";
import { Search } from "../../shared/layout/Search";
import "../style.less";
import useAxios from "../../shared/custom-hooks/useAxios";
import { type OrganizationDataType } from "./types";
import { useFilteredOrganisations } from "./utils";
import { useAppState } from "../../lib/appContext/AppContext";
import { Loader } from "../../shared/components/Loader";
import { route } from "../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import PlatFormAPIAxiosInstance from "../../shared/utils/platFormAxios";
import {
  applyOrgLiecningToOrgs,
  orgLicencing,
} from "../../shared/utils/orgLicencingUtil";

const HallOfOrganisations = (): ReactElement => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>("");
  const { response, loading } = useAxios("/orgs?attributes=workspaces", true);
  // const [userApps, setUserApps] = useState<any>({});
  const [licencingData, setLicencingData] = useState<any>([]);
  const [licencingStatus, setLicencingStatus] = useState({});
  const [organizations, setOrganizations] = useState<any>([]);
  const { stateValue, setStateValue } = useAppState();

  useEffect(() => {
    if (response?.content?.length) {
      getLicencingData();
    }
  }, [response]);

  useEffect(() => {
    if (licencingData.length) {
      const licencingStatusData = orgLicencing(response, licencingData);
      setLicencingStatus(licencingStatusData);
    }
  }, [licencingData]);

  useEffect(() => {
    const licencingAppliedOrgs: any = applyOrgLiecningToOrgs(
      response,
      licencingStatus
    );
    setOrganizations(licencingAppliedOrgs);
    setStateValue({
      orgsData: licencingAppliedOrgs,
      orgValue: null,
      farmValue: null,
      assigneeValue: null,
    });
  }, [licencingStatus]);

  const navigate = useNavigate();

  const getLicencingData = (): void => {
    const licencingIds = new Set<string>(
      response?.content.map((org: any) => {
        return org.licensing_account_id;
      })
    );
    const licencingDataResponse = Array.from(licencingIds).map(
      async (id: string) => {
        const lDataPromise = await PlatFormAPIAxiosInstance.get(
          `/licensing/accounts/${id}`
        );
        return lDataPromise;
      }
    );

    Promise.all(licencingDataResponse)
      .then((values) => {
        const licencingResp = values.map((lData) => {
          return lData.data;
        });
        setLicencingData(licencingResp);
      })
      .catch((err) => {
        console.error("Error Occurred", err);
      });
  };

  const filteredOrganisations = useFilteredOrganisations({
    response: organizations,
    searchText,
  });

  const onNavigate = (id: string): void => {
    navigate(`/app/${route.FARMS.path}`, { state: { id } });
    const getData: any = filteredOrganisations?.find((val: any) => {
      return val.id === id;
    });

    setStateValue({
      ...stateValue,
      orgValue: getData,
      farmValue: null,
      assigneeValue: null,
    });
  };

  return (
    <div className="hall-of-org-main">
      <div className="card_title">
        <h2>{formatTranslation(t("landingPage.title"))}</h2>
        <Search searchText={searchText} setSearchTexts={setSearchText} />
      </div>
      <div className="card-main">
        {loading ? (
          <Loader mask message="" />
        ) : (
          filteredOrganisations?.map(
            (org: OrganizationDataType, index: number) => (
              <OrgCard
                key={index}
                text={org.name}
                image={org.picture_url}
                onClick={onNavigate}
                id={org.id}
                disabled={!org.hasAccess}
              />
            )
          )
        )}
      </div>
    </div>
  );
};

export default HallOfOrganisations;
