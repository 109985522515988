import { Select, Table, type TableProps } from "antd";
import { Option } from "antd/es/mentions";
import { useNewTrialState } from "../../../pages/new-trail/hooks/NewTrialStateProvider";
import {
  type RatesDosageDataInterface,
  // type RatesDosageInterface,
} from "../../../pages/new-trail/types";
import { useEffect } from "react";
import { InputNumber } from "syngenta-digital-cropwise-react-ui-kit";
// import { useParams } from "react-router-dom";
import populateRatesDosage from "../../../pages/new-trail/hooks/PopulateRatesDosage";
import { get, remove, uniq } from "lodash";
import "./plotTable.less";
import { getCircleColor } from "./utils";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../utils/translationUtils";

interface DataType {
  index: number;
  key: React.Key;
  product: string;
  //   seedrate: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PlotCellColorBar = (props: any) => {
  const { data, products, ratesDosage } = props;
  return (
    <div
      className="plot-cell-color-bar-container"
      style={{
        background: getCircleColor(
          data?.seedrate?.variety,
          data?.seedrate?.rate,
          products,
          ratesDosage?.unit_of_rate
        ),
      }}
    >
      {props.children}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const PlotTable = (props: any) => {
  // const { id } = useParams();

  const { ratesAndDoses, productDetails, flattenedRates, vaildateField } = props;
  const {
    ratesDosage,
    setRatesDosage,
    newTrialState,
    setRatesDosageValidation,
  } = useNewTrialState();

  const { t } = useTranslation();

  const checkValidation = (): any => {
    for (const item of flattenedRates) {
      if (item.variety === "" || item.rate === null || item.errors.variety !== "" || item.errors.range !== "") { // empty scenario
        setRatesDosageValidation(false);
        break;
      }// disable button
      else setRatesDosageValidation(true); // enable button
    }
  }

  useEffect(() => {
    checkValidation();
  }, [ratesDosage.unit_of_rate]);

  const setSelectedRow = (selectedRowsList: string[]): any => {
    const ratesDosageValue = populateRatesDosage(
      newTrialState.trial_plots,
      selectedRowsList,
      ratesDosage?.rates_and_dosages,
      ratesDosage?.unit_of_rate
    );
    setRatesDosage(ratesDosageValue);
  };

  const rowSelection = {
    onSelect: (_record: any, _selected: boolean) => {
      // Find selected rows
      const existing: any = get(
        ratesDosage,
        "rates_and_dosages[0][0].collapseValue",
        []
      );

      const currentRow = _record.key;

      // Toggle current row depending on user action
      _selected
        ? existing.push(currentRow)
        : remove(existing, (row) => row === currentRow);

      // Update selected row
      setSelectedRow(existing);
    },
    onChange: (
      _selectedRowKeys: React.Key[],
      selectedRows: DataType[],
      _info: any
    ) => {
      if (_info.type === "all") {
        // Reset all rows from all tables and toggle all rows from current table
        const selectedRowsList = selectedRows.map((f) => f.key as string);
        setSelectedRow(selectedRowsList);
      }
    },
  };

  const handleUpdateValue = (value: string, plotId: number): void => {
    const updatedRatesDosage = ratesDosage.rates_and_dosages.map(
      (outerItem: any) => {
        return outerItem.map((innerItem: RatesDosageDataInterface) => {
          if (innerItem.plot_id === plotId) {
            return { ...innerItem, variety: value };
          }
          return innerItem;
        });
      }
    );
    setRatesDosage({
      rates_and_dosages: updatedRatesDosage,
      type: ratesDosage.type,
      unit_of_rate: ratesDosage.unit_of_rate,
    });
  };

  const handleInputValue = (values: number | null, data: any): void => {
    vaildateField(data);

    const updatedRatesDosageMap = ratesDosage?.rates_and_dosages?.map(
      (ratesDosagesArray: any) => {
        return ratesDosagesArray.map((ratesDosagesItem: any) => {
          if (ratesDosagesItem?.plot_id === data?.plot_id) {
            return { ...ratesDosagesItem, rate: values };
          }
          return ratesDosagesItem;
        });
      }
    );
    setRatesDosage({
      rates_and_dosages: updatedRatesDosageMap,
      type: ratesDosage.type,
      unit_of_rate: ratesDosage.unit_of_rate,
    });
  };

  const columns: TableProps<DataType>["columns"] = [
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header1")),
      dataIndex: "index",
      render: (_, { index }) => {
        return <>{index}</>;
      },
    },
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header2")),
      dataIndex: "product",
      render: (data) => {
        const err = vaildateField(data);
        for (const item of flattenedRates) {
          if (item.plot_id === data.plot_id) {
            item.errors = { ...err };
          }
        }
        checkValidation();

        return (
          <div>
            <Select
              placeholder={formatTranslation(t("trials.plotDesign.trialPlots.select.placeholder"))}
              value={data.variety ?? undefined}
              className="plottable-seelct-product select-item"
              onChange={(values) => {
                handleUpdateValue(values, data.plot_id);
              }}
            >
              {productDetails?.products ? (
                productDetails?.products.map((product: any, index: any) => {
                  return (
                    <Option key={index} value={product.variety}>
                      {product.variety}
                    </Option>
                  );
                })
              ) : (
                <></>
              )}
            </Select>
            <div>
              <span className="error-message">{err.range}</span>
              <span className="error-message">{err.variety}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: formatTranslation(t("trials.plotDesign.trialPlots.table.header3")),
      dataIndex: "seedrate",
      width: 100,
      render: (data) => (
        <>
          <InputNumber
            type="number"
            value={data.rate || undefined}
            onChange={(values) => {
              handleInputValue(values, data);
            }}
            className="input-number"
          />
        </>
      ),
    },
  ];
  const dataSource: DataType[] = ratesAndDoses?.map((r: any, index: number) => {
    return {
      index: index + 1,
      key: r.plot_id,
      product: r,
      seedrate: r,
    };
  });
  const selectedRowKeys = uniq(
    get(ratesDosage, "rates_and_dosages[0][0].collapseValue", [])
  );

  return (
    <Table
      className="trialing-plot-table-container"
      pagination={false}
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
        renderCell(value, record, index, originNode) {
          return (
            <PlotCellColorBar
              data={record}
              products={productDetails?.products}
              ratesDosage={ratesDosage}
            >
              {originNode}
            </PlotCellColorBar>
          );
        },
        selectedRowKeys,
      }}
      columns={columns}
      dataSource={dataSource}
    />
  );
};

export default PlotTable;
