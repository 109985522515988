import { type FC } from "react";
import { Button, ButtonType } from "syngenta-digital-cropwise-react-ui-kit";
import { type Props } from "../types";
import "./primaryButton.less";

export const PrimaryButton: FC<Props> = ({ children, ...props }) => {
  return (
    <Button type={ButtonType.primary} {...props}>
      {children}
    </Button>
  );
};
