export const SupportedLanguages = [
  { value: "en", label: "English", shortLabel: "EN" },
  { value: "en-GB", label: "English (UK)", shortLabel: "En-GB" },
  { value: "bg", label: "Bulgarian", shortLabel: "BG" },
  { value: "cs", label: "Czech", shortLabel: "CS" },
  { value: "fr", label: "French", shortLabel: "FR" },
  { value: "de", label: "German", shortLabel: "DE" },
  { value: "hu", label: "Hungarian", shortLabel: "HU" },
  { value: "it", label: "Italian", shortLabel: "IT" },
  { value: "pl", label: "polski", shortLabel: "PL" },
  { value: "pt", label: "Portuguese", shortLabel: "PT" },
  { value: "pt-BR", label: "Portuguese (Brazil)", shortLabel: "PT-BR" },
  { value: "ro", label: "Romanian", shortLabel: "RO" },
  { value: "sk", label: "Slovak", shortLabel: "SK" },
  { value: "es", label: "Spanish", shortLabel: "ES" },
  { value: "uk", label: "Ukrainian", shortLabel: "UA" },
  { value: "es-AR", label: "Spanish" },
];
