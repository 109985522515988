import { type TabsProps } from "antd";
import SeedsComponent from "./seeds";
import CropProtectionComponent from "./cropProtection";
import FertiliserComponent from "./fertiliser";
import "../style.less";

export const RATES_DOSAGE_ITEMS = (trialData: any): TabsProps["items"] => [
  {
    key: "0",
    label: <div className="label-style">Seeds</div>,
    children: <SeedsComponent trialData={trialData} />,
  },
  // {
  //   key: "1",
  //   label: "Fertiliser",
  //   children: <FertiliserComponent />,
  // },
  // {
  //   key: "2",
  //   label: "Crop Protection",
  //   children: <CropProtectionComponent />,
  // },
];
